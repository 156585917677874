import { IdName, NCompany, NDevice, NLocation, TemplateLibrary } from '../../dataStore';
import {
  Channel,
  ChannelType,
  NStation,
  OnHold,
  Visual,
  Stream,
  Zone,
} from '../../dataStore/types';
import {
  apiFetch,
  currentWorkgroupKey,
  fetchHelper,
  getCookieValue,
  getWithVersion,
  postWithReturnedVersion,
  putWithVersion,
} from '../../v6/ajax';
import { anthemApi } from './api/anthemApi';
import { stationApi } from './api/stationApi';
import {
  ImportFileParseResult,
  ImportFileResult,
  SearchEntity,
  SearchFields,
  StationDto,
} from './types';

type CompanyDataWithoutDeviceSettings = Pick<NCompany, Exclude<keyof NCompany, 'deviceSettings'>>;

type LocationDataWithoutDeviceSettings = Pick<
  NLocation,
  Exclude<keyof NLocation, 'deviceSettings'>
>;

export const NewnityApi = {
  ...anthemApi,
  ...stationApi,
  getCompanyInfo: async (companyId: number): Promise<NCompany> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company/${companyId}`
    );

    return response.json();
  },

  saveCompanyInfo: async (company: NCompany, createMode: boolean): Promise<NCompany> => {
    let response;
    const url = process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company`;
    const saveUrl = createMode ? url : `${url}/${company.id}`;
    response = await fetchHelper(saveUrl, createMode ? 'POST' : 'PUT', company);
    return response.json();
  },

  getComanpies: async (): Promise<IdName[]> => {
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company`);
    return response.json();
  },

  search: async (fields: SearchFields, entity: SearchEntity): Promise<IdName[]> => {
    const workgroupId =
      (process.env.REACT_APP_HEADER_01 as string) === 'MVision-Current-WorkgroupId'
        ? Number(getCookieValue(currentWorkgroupKey))
        : 1;
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/search`,
      'POST',
      {
        workgroupId,
        entity,
        fields,
      }
    );

    return response.json();
  },

  getLocations: async (companyId: number): Promise<NLocation[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/list/${companyId}`
    );

    return response.json();
  },

  getLocation: async (id: number): Promise<NLocation> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/${id}`
    );

    return response.json();
  },
  getDeleteLocations: async (locationIds: number[]) => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/getDeleteLocations`,
      'POST',
      { siteIds: locationIds }
    );
    return response.json();
  },

  saveLocation: async (
    companyId: number,
    location: NLocation,
    create?: boolean
  ): Promise<NLocation> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/${companyId}`,
      create ? 'POST' : 'PUT',
      location
    );
    return response.json();
  },

  deleteLocations: async (locations: NLocation[]) => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/deleteLocations`,
      'POST',
      { workgroupIds: locations.map((e) => e.workgroupId), siteIds: locations.map((e) => e.id) }
    );
    return response.status;
  },
  saveDevice: async (
    device: NDevice,
    create?: boolean
  ): Promise<{ error: string; device: NDevice }> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices`,
      create ? 'POST' : 'PUT',
      device
    );

    return response.json();
  },
  assignDeviceToPlaylist: async (
    playerId: number,
    stationId: number,
    channelId: number,
    serial: string
  ): Promise<Response> => {
    return fetchHelper(`${process.env.REACT_APP_ROOT_DOMAIN}music/api/device/station`, 'PUT', {
      playerId,
      stationId,
      channelId,
      serial,
    });
  },
  deleteDevice: async (deviceId: number): Promise<{ error: string; device: NDevice }> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices/${deviceId}`,
      'DELETE'
    );

    return response.json();
  },
  getDevice: async (deviceId: number): Promise<NDevice> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices/${deviceId}`
    );

    return response.json();
  },

  getDevices: async (companyId: number): Promise<NDevice[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices/byCompany/${companyId}`
    );

    return response.json();
  },

  getMusicChannels: async (workgroupId: number): Promise<Zone[]> => {
    return (await NewnityApi.getChannels(workgroupId, ChannelType.Music)) as Zone[];
  },

  getVisualChannels: async (workgroupId: number): Promise<Visual[]> => {
    const video = (await NewnityApi.getChannels(workgroupId, ChannelType.Video)) as Visual[];
    const videoOnDeman = (await NewnityApi.getChannels(workgroupId, ChannelType.VOD)) as Visual[];

    return [...video, ...videoOnDeman];
  },

  getVisual: async (visualId: number): Promise<Visual> => {
    return (await NewnityApi.getChannel(visualId)) as Visual;
  },

  saveVisual: async (
    visual: Visual,
    create: boolean
  ): Promise<{ channelId: number; channelVersion: string }> => {
    return await NewnityApi.saveChannel(visual, create);
  },

  getOnHoldChannels: async (workgroupId: number): Promise<OnHold[]> => {
    return (await NewnityApi.getChannels(workgroupId, ChannelType.OnHold)) as OnHold[];
  },

  getOnHold: async (onHoldId: number): Promise<OnHold> => {
    return (await NewnityApi.getChannel(onHoldId)) as OnHold;
  },

  saveOnHold: async (
    onHold: OnHold,
    create: boolean
  ): Promise<{ channelId: number; channelVersion: string }> => {
    return await NewnityApi.saveChannel(onHold, create);
  },

  deleteOnHold: async (onHoldId: number): Promise<Response> => {
    // const response = await fetchHelper(
    //   process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/channel/${onHoldId}`,
    //   'DELETE',
    // );

    // return response;
    return new Promise((resolve, reject) => resolve({ ok: true } as Response));
  },

  getChannels: async (workgroupId: number, channelType?: ChannelType): Promise<Channel[]> => {
    const url = `v6/adminApp/channel/list/${workgroupId}` + (channelType ? `/${channelType}` : '');
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + url);

    return response.json();
  },

  getChannel: async (channelId: number): Promise<Channel> => {
    const result = await getWithVersion<Zone>(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/channel/${channelId}`
    );

    return {
      ...result.data,
      rowVersion: result.version,
    };
  },

  getZone: async (zoneId: number): Promise<Zone> => {
    return (await NewnityApi.getChannel(zoneId)) as Zone;
  },

  getZoneStations: async (companyId: number, zoneId: number): Promise<NStation[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/channel/musicStations/${companyId}/${zoneId}`
    );
    return response.json();
  },

  getStream: async (streamId: number): Promise<Stream> => {
    const response = await getWithVersion<Stream>(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/stream/${streamId}`
    );
    return { ...response.data, rowVersion: response.version };
  },

  createStream: async (stream: Stream): Promise<Stream> => {
    const response = await postWithReturnedVersion<number>(
      process.env.REACT_APP_ROOT_DOMAIN + 'v6/adminApp/stream',
      stream
    );

    return { ...stream, id: response.data, rowVersion: response.version };
  },

  updateStream: async (stream: Stream): Promise<Stream> => {
    const response = await putWithVersion<number>(
      process.env.REACT_APP_ROOT_DOMAIN + 'v6/adminApp/stream',
      { ...stream, rowVersion: undefined },
      stream.rowVersion
    );

    return { ...stream, rowVersion: response.version };
  },

  deleteStream: async (streamId: number) => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/stream/${streamId}`,
      'DELETE'
    );

    return response;
  },

  saveZone: async (
    zone: Zone,
    create: boolean
  ): Promise<{ channelId: number; channelVersion: string }> => {
    return await NewnityApi.saveChannel(zone, create);
  },

  saveChannel: async (
    channel: Channel,
    create: boolean
  ): Promise<{ channelId: number; channelVersion: string }> => {
    let response: any;

    if (create) {
      response = await postWithReturnedVersion<number>(
        process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/channel`,
        channel
      );
    } else {
      response = await putWithVersion<number>(
        process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/channel`,
        channel,
        channel.rowVersion
      );
    }

    return {
      channelId: response.data,
      channelVersion: response.version,
    };
  },

  getTemplateLibraries: async (): Promise<TemplateLibrary[]> => {
    return (
      await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/templateLibraries`)
    ).json();
  },

  deleteZone: async (zoneId: number): Promise<Response> => {
    // const response = await fetchHelper(
    //   process.env.REACT_APP_ROOT_DOMAIN + `v6/adminApp/channel/${zoneId}`,
    //   'DELETE',
    // );

    // return response;
    return new Promise((resolve, reject) => resolve({ ok: true } as Response));
  },

  getStation: async (stationId: number, zoneId: number): Promise<StationDto> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/channel/musicStation/${stationId}/${zoneId}`
    );
    return response.json();
  },

  getDefaultParentWorkgroup: async (companyId?: number): Promise<NCompany> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        'v6/admin/newnity/company/parent' +
        (companyId ? `/${companyId}` : '')
    );

    return response.json();
  },

  getImportTemplateUrl: () =>
    process.env.REACT_APP_ROOT_DOMAIN + 'v6/admin/newnity/import/template',
  getImportTemplateDriveUrl: () =>
    process.env.REACT_APP_ROOT_DOMAIN + 'v6/admin/newnity/import/driveTemplate',
  checkImportFile: async (
    remotePath: string,
    companyId: number
  ): Promise<ImportFileParseResult> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/import/parse/${remotePath}?companyId=${companyId}`
    );
    return response.json();
  },
  importFile: async (remotePath: string, companyId: number): Promise<ImportFileResult> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/import/${remotePath}?companyId=${companyId}`,
      { method: 'POST' }
    );
    return response.json();
  },
};
