import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BladeActions, BladeProps, openBlade, withRegisterBlade } from 'react-tools';

import { DataStoreSelectors, EntityType } from '../../../../dataStore';
import { locationStartEdit } from '../../../duck/actions';
import {
  selectCompanyId,
  selectDeletingLocation,
  selectIsEditingLocation,
} from '../../../duck/selectors';
import { deleteLocation, fetchLocations } from '../../../duck/thunks';
import { LocationEditBladeType } from '../edit/locationEditContainer';
import { LocationList, LocationListProps } from './locationList';

export const LocationListBladeType = 'NEWNITY_LOCATION_LIST';

const bladeConfig = {
  size: { defaultWidth: 700, minWidth: 600 },
  bladeType: LocationListBladeType,
  id: (_: any) => LocationListBladeType,
  title: (_: any) => 'Locations',
  allowMultipleInstances: false,
};

const ContainerComponent = (props: LocationListProps & BladeProps) => {
  const dispatch = useDispatch();
  const companyId = useSelector(selectCompanyId);
  const items = useSelector(DataStoreSelectors.NLocation.selectLocationItems);
  const locationsFetching = useSelector(
    (state) => DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NLocation).isFetching
  );
  const locationDeleting = useSelector(selectDeletingLocation);
  const isEditing = useSelector(selectIsEditingLocation);

  const fetchLocationsForCompany: (companyId: number) => any = useCallback(
    (companyId: number) => dispatch<any>(fetchLocations(companyId)),
    [dispatch]
  );

  const createLocation = useCallback(
    () =>
      dispatch(
        BladeActions.closeChildrenBlades(props.bladeId, [
          openBlade(props.bladeId, LocationEditBladeType, {}),
          locationStartEdit(),
        ])
      ),
    [dispatch]
  );

  const editLocation = useCallback(
    (id: number, name: string) =>
      dispatch(
        BladeActions.closeChildrenBlades(props.bladeId, [
          openBlade(props.bladeId, LocationEditBladeType, {
            locationSiteId: id,
            locationName: name,
          }),
          locationStartEdit(),
        ])
      ),
    [dispatch]
  );

  const deleteLocationFn: (id: number) => any = useCallback(
    (id: number) => {
      const location = items.find((e) => e.id === id);
      if (location) {
        dispatch<any>(deleteLocation(location, props.bladeId));
      }
    },
    [items]
  );

  return (
    <LocationList
      fetchLocations={fetchLocationsForCompany}
      createLocation={createLocation}
      editLocation={editLocation}
      deleteLocation={deleteLocationFn}
      companyId={companyId}
      isEditing={isEditing}
      items={items}
      locationDeleting={locationDeleting}
      locationsFetching={locationsFetching}
    />
  );
};

export const LocationListContainer = withRegisterBlade<LocationListProps & BladeProps>(bladeConfig)(
  ContainerComponent
);
