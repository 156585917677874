import { IdName, NLocation } from 'app/dataStore/types';
import { NewnityApi } from 'app/newnity/duck/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonItem,
  ColumnSettings,
  createEditDelete,
  DataGrid,
  DataGridSelectionType,
  pushNotification,
  useBladeButtons,
} from 'react-tools';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import { useStyles } from './locationList.jss';

export interface LocationListProps {
  companyId: number;
  items: NLocation[];
  locationsFetching: boolean;
  locationDeleting: boolean;
  isEditing: boolean;
}

export interface LocationListActions {
  fetchLocations: (companyId: number) => void;
  createLocation: () => void;
  editLocation: (id: number, name: string) => void;
  deleteLocation: (id: number) => void;
}

type Props = LocationListActions & LocationListProps;

interface DeleteConfirmDialogProps {
  open: boolean;
  locationId: number;
  locationName: string;
  isEditing: boolean;
  onCancel: () => void;
  onDelete: (id: number, name: string) => void;
}

const DeleteConfirmDialog = React.memo(
  (props: DeleteConfirmDialogProps) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const [confirmDelete, handleInputChange] = useState('');
    const [linkedEntities, fetchLinkedEntities] = useState([{ workgroups: 0, hardwares: 0 }]);
    const [isLoading, setIsLoading] = useState(true);

    const { onDelete } = props;

    const handleDelete = useCallback(
      (locationId: number, locationName: string) => {
        onDelete(locationId, locationName);
        handleInputChange('');
      },
      [onDelete]
    );

    const handleCancel = () => {
      props.onCancel();
      handleInputChange('');
    };
    useEffect(() => {
      if (props.locationId !== 0) {
        NewnityApi.getDeleteLocations([props.locationId])
          .then((result) => {
            fetchLinkedEntities(result);
            setIsLoading(false);
          })
          .catch((err) =>
            pushNotification(`Error occured trying to fetch location for delete: ${err}`)
          );
      }
    }, [props.open, props.locationId]);

    return (
      <Dialog open={props.open}>
        <DialogTitle>{t<string>('newnity.list.location.delete.confirm.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.warningMessage}>
            {props.isEditing ? t('newnity.list.location.delete.currently.editing') : ''}
            {t<string>('newnity.list.location.delete.confirm.content', {
              locationName: props.locationName,
            })}
            <br />
            <b>{t<string>('newnity.list.location.delete.confirm.warning')}</b>
          </DialogContentText>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t<string>('newnity.list.location.delete.table.location')}</TableCell>
                <TableCell>{t<string>('newnity.list.location.delete.table.device')}</TableCell>
                <TableCell>{t<string>('newnity.list.location.delete.table.workgroup')}</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && (
              <TableBody>
                {linkedEntities.map((x) => (
                  <TableRow>
                    <TableCell>{props.locationName}</TableCell>
                    <TableCell>{x.hardwares}</TableCell>
                    <TableCell>{x.workgroups}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <TextField
            autoFocus
            margin="dense"
            id="confirmDelete"
            label="Type Yes to Delete"
            fullWidth
            value={confirmDelete}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" autoFocus>
            {t<string>('cancel')}
          </Button>
          <Button
            onClick={() => {
              handleDelete(props.locationId, props.locationName);
            }}
            color="primary"
            disabled={confirmDelete.toUpperCase() !== 'YES'}
          >
            {t<string>('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => prevProps.open === nextProps.open
);

const columnSettings: ColumnSettings[] = [
  {
    name: 'oracleNumber',
    width: 240,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'name',
    width: 170,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'clientSiteId',
    width: 140,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'country',
    width: 120,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'city',
    width: 120,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'timezone',
    width: 140,
    filter: 'agTextColumnFilter',
  },
];

export const LocationList: React.FunctionComponent<Props> = (props) => {
  const { fetchLocations } = props;

  const [selected, setSelected] = useState<IdName[]>([]);

  useEffect(() => {
    fetchLocations(props.companyId);
  }, [props.companyId, fetchLocations]);

  const [deleteConfirmLocation, setDeleteConfirmLocation] = useState<{
    id: number;
    name: string;
    delete: boolean;
  }>({ id: 0, name: '', delete: false });

  const classes = useStyles();

  const editLocation = useCallback(() => {
    if (selected.length > 0) {
      props.editLocation(selected[0].id, selected[0].name);
    }
  }, [props.editLocation, selected]);

  const deleteLocation = useCallback(() => {
    if (selected.length > 0) {
      const location = selected[0];
      setDeleteConfirmLocation({
        id: location.id,
        name: location.name,
        delete: true,
      });
    }
  }, [selected]);

  createEditDelete(props.createLocation, editLocation, deleteLocation);

  const headerButtons = useMemo<ButtonItem[]>(
    () => [
      {
        icon: () => <Add />,
        onClick: props.createLocation,
        tooltip: 'locations.tooltip.add',
      },
      {
        icon: () => <Edit />,
        onClick: editLocation,
        tooltip: 'locations.tooltip.edit',
      },
      {
        icon: () => <Delete />,
        onClick: deleteLocation,
        tooltip: 'locations.tooltip.delete',
      },
    ],
    [props.createLocation, editLocation, deleteLocation]
  );

  useBladeButtons(headerButtons);

  const setLocations = useCallback((items: IdName[]) => {
    setSelected(items as IdName[]);
  }, []);

  return (
    <div className={classes.container}>
      <DataGrid
        identifier={'locations'}
        idColumn={'id'}
        onSelect={setLocations}
        items={props.items}
        selectionType={DataGridSelectionType.SingleOrNone}
        showSelectionColumn
        columnSettings={columnSettings}
        rowClass={classes.alignedRow}
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
      />
      <DeleteConfirmDialog
        open={deleteConfirmLocation.delete}
        locationName={deleteConfirmLocation.name}
        locationId={deleteConfirmLocation.id}
        isEditing={props.isEditing}
        onDelete={(id) => {
          props.deleteLocation(id);
          setDeleteConfirmLocation({
            id: 0,
            name: '',
            delete: false,
          });
        }}
        onCancel={() =>
          setDeleteConfirmLocation({
            id: 0,
            name: '',
            delete: false,
          })
        }
      />
    </div>
  );
};
