export const resources = {
  en: {
    translation: {
      yes: 'Yes',
      no: 'No',
      all: 'All',
      help: 'Help',
      'Welcome to Admin App': "Admin App is awesome y'all",
      home: 'Home',
      workgroups: 'Workgroups',
      settings: 'Settings',
      users: 'Users',
      players: 'Players',
      sites: 'Sites',
      or: 'or',
      'blade.closeDialog.title': 'Close blade',
      'blade.closeDialog.message': 'Are you sure you want to close these blades?',
      'blade.closeDialog.message.secondLine': 'Some of them have unsaved data:',
      'blade.close.all': 'Close all',
      'blade.dirty.warning': 'This blade has unsaved changes',
      'blade.openHelpDialogButtonTooltip': 'Show help dialog',
      'blade.closeBladeButtonTooltip': '',
      cancel: 'Cancel',
      continue: 'Continue',
      exit: 'Exit',
      add: 'Add',
      remove: 'Remove',
      create: 'Create',
      import: 'Import',
      delete: 'Delete',
      select: 'Select',
      warning: 'Warning',
      favorites: 'Favorites',
      search: 'Search',
      upload: 'Upload',
      next: 'Next',
      skip: 'Skip',
      close: 'Close',
      'search.placeholder': 'Type something for search...',
      'workgroups.pickSelectionModeCaption': 'You are now in the Workgroup Pick Mode!',
      'workgroups.expand.tooltip': 'Expand all workgroups',
      'workgroups.collapse.tooltip': 'Collapse all workgroups',
      'workgroups.select.tooltip': 'Select all workgroups',
      'workgroups.unselect.tooltip': 'Deselect all workgroups',
      'workgroups.export.tooltip': 'Export selected workgroups',
      name: 'Name',
      save: 'Save',
      edit: 'Edit',
      logo: 'Logo',
      'field.required': 'Field is required',
      'device.settings.title': 'Device settings',
      'templateLibraries.listTitle': 'Template Libraries',
      'templateLibraries.id': 'Id',
      'templateLibraries.name': 'Name',
      'templateLibraries.empty': 'No templates',
      'templateLibraries.submitButtonLabel': 'APPLY',
      'workgroupLocations.quickSearch': 'Quick Search',
      'select.brands': 'Brands',
      'workgroups.templateLibraries.brand': 'Brand',
      'workgroups.templateLibraries.logo': 'Logo Path',
      'workgroups.brands.defaultLogo': 'Default Logo',
      'workgroups.brands.brandType': 'Brand Type',
      'create.workgroup.name': 'Workgroup name',
      'create.workgroup.brand_label': 'Brand',
      'create.workgroup.brand_ids': "Brand Id's",
      'create.workgroup.template': 'Template Libraries',
      'create.workgroup.parent_name': 'Parent workgroup',
      'create.workgroup.edit_btn_label': 'EDIT',
      'create.workgroup.select_btn_label': 'SELECT',
      'create.workgroup.error_name_required': 'Field is required',
      'create.workgroup.create_btn_label': 'CREATE',
      'create.workgroup.cancel_btn_label': 'Cancel',
      'create.workgroup.create_another_btn_label': 'Create another',
      'create.workgroup.error.multiple_workgroups':
        'Cannot create workgroup: multiple parents selected',
      'create.workgroup.number_selected': 'selected',
      'create.workgroup.properties_header': 'Properties',
      'workgroup.users': 'Users',
      'workgroup.users.remove': 'Remove Users',
      'workgroup.users.add': 'Add Users',
      'workgroup.users.edit': 'Edit User',
      'workgroup.users.remove.confirm.title': 'Remove User',
      'workgroup.users.remove.confirm.message':
        'Are you sure you want to remove user {{userLogin}}?',
      'workgroup.users.remove.confirm.generic': 'Are you sure you want to remove {{count}} users?',
      'workgroup.users.remove.confirm.editingUser': 'You are currently editing this user. ',
      'workgroup.users.dialog.remove': 'Remove',
      'workgroup.users.isLocked.unlocked': 'Unlocked',
      'workgroup.users.isLocked.locked': 'Locked',
      'workgroup.users.isLocked.masterlocked': 'Locked',
      'workgroup.users.isLocked.inactivity': 'Locked- inactivity',
      'workgroupUsers.onlyWorkgroupAvailable': 'Access Type',
      'workgroupUsers.value.onlyWorkgroupAvailable': 'Exclusive',
      'workgroupUsers.value.multipleWorkgroupsAvailable': 'Non-Exclusive',
      'workgroupUsers.id': 'ID',
      'workgroupUsers.login': 'Login',
      'workgroupUsers.email': 'Email',
      'workgroupUsers.firstName': 'First Name',
      'workgroupUsers.lastName': 'Last Name',
      'workgroupUsers.passwordExpDate': 'Password Expire',
      'workgroupUsers.lockLevel': 'Is Locked',
      'workgroupUsers.lockReason': 'Lock Reason',
      'workgroupUsers.addExistingUser': 'Add Existing User',
      'workgroupUsers.tooltip.add': 'Add existing user',
      'workgroupUsers.tooltip.create': 'Create new user',
      'workgroupUsers.tooltip.edit': 'Edit user',
      'workgroupUsers.tooltip.delete': 'Remove user',

      'user.userAccessTitle': 'User Access',
      'user.userAccessSelectionRequired': 'Please select an access right type',
      'user.userHasExtendedAccess':
        '* User has extended application access. (One or more additional applications)',
      'user.messageOnlyAccessRightsTitle': 'Messaging only: ',
      'user.messageOnlyAccessRightsDesc': 'Messaging, Devices, Locations, Reports',
      'user.musicOnlyAccessRightsTitle': 'Music only: ',
      'user.musicOnlyAccessRightsDesc': 'Music, Messaging, Devices, Locations, Reports',
      'user.visualsOnlyAccessRightsTitle': 'Visuals only: ',
      'user.visualsOnlyAccessRightsDesc': 'Visuals, Devices, Locations, Reports',
      'user.visualsAndMusicAccessRightsTitle': 'Music and Visuals: ',
      'user.visualsAndMusicAccessRightsDesc':
        'Music, Messaging, Visuals, Devices, Locations, Reports',

      'user.edit.firstName': 'First Name',
      'user.edit.lastName': 'Last Name',
      'user.edit.login': 'Login ID',
      'user.edit.email': 'Email',
      'user.edit.password': 'Password',
      'user.edit.passwordVerify': 'Verify Password',
      'user.edit.changePassword': 'Change Password',
      'user.edit.changeLogin': 'Change Login',
      'user.edit.passwordError': 'Passwords must match',
      'user.edit.login.space.validation': "Login can't contain spaces",
      'user.edit.uncomplaint.password':
        'Password must be at least 12 characters long and contain at least one lower case letter, one upper case letter, one digit and one special character',
      'user.edit.invalid.email': 'Email must be of format [text]@[domain].[extension]',
      'user.edit.welcomeEmailTemplate': 'Email Template',
      'user.edit.sendWelcomeEmailOnCreate': 'Send welcome email on create',
      'user.edit.sendWelcomeEmailOnSave': 'Send welcome email on save',
      'user.edit.lastWelcomeEmailDate': 'Last welcome email sent on',
      'user.edit.previewEmail': 'Preview',
      'users.company.blade.title': 'Company Users',
      'newnity.startup.anthemAuthenticationFailure': 'Could not authenticate into Anthem!',
      'newnity.company': 'Company',
      'newnity.device': 'Device',
      'newnity.location': 'Location',
      'newnity.stations': 'Playlists',
      'newnity.locations': 'Locations',
      'newnity.devices': 'Devices',
      'newnity.music': 'Music',
      'newnity.visuals': 'Visuals',
      'newnity.onHold': 'On-Hold',
      'newnity.deviceSettings': 'Device settings',
      'newnity.import': 'Import',
      'newnity.import.blade.title': 'Import',
      'newnity.import.step.getTemplate.title': 'Get the template',
      'newnity.import.step.getTemplate.downloadFile': 'Download file',
      'newnity.import.step.getTemplate.openInDrive': 'Open in drive',
      'newnity.import.step.getTemplate.skipThisStep': 'Skip this step',
      'newnity.import.step.uploadFile.title': 'Upload the file',
      'newnity.import.step.checkFileData.progressMessage': 'Checking file data',
      'newnity.import.step.checkFileData.title': 'Check import file data',
      'newnity.import.step.checkFileData.message':
        'Lines containing errors will be skipped at import',
      'newnity.import.step.checkFileData.error.message':
        'You have errors in your import file. The import cannot continue. Please fix the errors and try again.',
      'newnity.import.summary.entity': 'Entity',
      'newnity.import.summary.total': 'Total',
      'newnity.import.summary.completed': 'Completed',
      'newnity.import.summary.errors': 'Errors',
      'newnity.import.summary.warnings': 'Warnings',
      'newnity.import.summary.skipped': 'Skipped',
      'newnity.import.summary.devicesSkippedTitle': 'Skipped devices',
      'newnity.import.summary.locationsSkippedTitle': 'Skipped locations',
      'newnity.import.summary.fileErrors': 'Import file not compliant',
      'newnity.import.summary.deviceSettingsSkippedTitle': 'Skipped Settings',
      'newnity.import.step.importData.title': 'Import data',
      'newnity.import.step.importData.duplicatesMessage': 'If an entity already exists, then:',
      'newnity.import.step.importData.progressMessage': 'Importing data',
      'newnity.import.step.importData.radio.update': 'Update values',
      'newnity.import.step.importData.radio.skip': 'Skip',
      'newnity.import.step.importData.completedMessage': 'Import complete, here are the results:',
      'importSummary.name': "Description",
      'importSummary.row': 'Row',
      'importSummary.columnName': 'Column',
      'importSummary.worksheetName': 'Worksheet Name',
      'importSkippedRow.columnTitle.name': 'Reason',
      'importSkippedRow.columnTitle.row': 'Row',
      'importSkippedRow.columnTitle.columnName': 'Column',
      'newnity.toolbar.search': 'Search',
      'newnity.toolbar.search.advanced': 'Advanced Search',
      'newnity.toolbar.add': 'New company',
      'newnity.toolbar.info': 'Company Info',
      'newnity.toolbar.close': 'Close company',
      'newnity.toolbar.jump': 'Jump to company',
      'newnity.toolbar.jump.tooltip': 'Quick Search Company',
      'newnity.search': 'Search',
      'newnity.address': 'Address',
      'newnity.phone': 'Phone',
      'newnity.search.address': 'Street Address',
      'newnity.search.city': 'City',
      'newnity.search.state': 'State/Province',
      'newnity.search.zip': 'Postal code',
      'newnity.search.country': 'Country',
      'search.id': 'Id',
      'search.name': 'Company name',
      'search.number': 'Company number',
      'search.firstName': 'Contact first name',
      'search.lastName': 'Contact last name',
      'search.phone': 'Phone',
      'search.email': 'Email',
      'search.deviceNumber': 'Device number',
      'search.deviceDescription': 'Device description',
      'search.serial': 'Serial number',
      'search.locationNumber': 'Location number',
      'search.locationName': 'Location name',
      'search.siteNumber': 'Site number',
      'search.company': 'Company',
      'search.companyName': 'Company name',
      'search.salesOrder': 'Sales order number',
      'search.city': 'City',
      'search.state': 'State/Province',
      'search.zip': 'Postal code',
      'search.country': 'Country',
      'newnity.search.location.locationNumber': 'Location number',
      'newnity.search.location.locationName': 'Location name',
      'newnity.search.location.deviceNumber': 'Device number',
      'newnity.search.location.serial': 'Device serial',
      'newnity.search.location.siteNumber': 'Site Number',
      'newnity.search.location.company': 'Company',
      'newnity.search.device.deviceNumber': 'Device Number',
      'newnity.search.device.deviceDescription': 'Description',
      'newnity.search.device.serial': 'Device serial',
      'newnity.search.device.locationNumber': 'Location number',
      'newnity.search.device.locationName': 'Location name',
      'newnity.search.device.siteNumber': 'Site number',
      'newnity.search.device.company': 'Company',
      'newnity.search.device.salesOrder': 'Sales order number',
      'newnity.search.phone': 'Phone',
      'newnity.search.email': 'Email',
      'newnity.search.resultsPlaceholder': 'Search results',
      'newnity.edit.company.blade.create.title': 'Create company',
      'newnity.edit.company.tab.title': 'Company details',
      'newnity.company.field.error': 'Field is required',
      'newnity.company.name': 'Company name',
      'newnity.company.number': 'Company number',
      'newnity.search.company.number': 'Company number',
      'newnity.search.company.name': 'Company Name',
      'newnity.search.company.phone': 'Phone',
      'newnity.search.company.firstName': 'First Name',
      'newnity.search.company.lastName': 'Last Name',
      'newnity.search.company.email': 'Email',
      'newnity.company.parentWorkgroup': 'Parent workgroup',
      'newnity.company.address': 'Address',
      'newnity.company.address.country': 'Country',
      'newnity.company.address.city': 'City',
      'newnity.company.address.state': 'State/Province',
      'newnity.company.address.zipCode': 'Zip/Postal code',
      'newnity.company.address.streetAddress': 'Street address',
      'newnity.company.contact.firstName': 'First name',
      'newnity.company.contact.lastName': 'Last name',
      'newnity.company.contact.phone': 'Phone',
      'newnity.company.contact.email': 'Email address',
      'newnity.edit.company.toast.create.success': 'Company created successfully',
      'newnity.edit.company.toast.success': 'Company data saved',
      'footer.save': 'Save',
      'footer.create': 'Create',
      'footer.cancel': 'Cancel',
      'footer.clear': 'Clear',

      'newnity.edit.zone.blade.create.title': 'Create zone',
      'newnity.edit.zone.blade.create.header': 'Zone',
      'newnity.edit.zone.blade.create.name': 'Name',
      'newnity.edit.zone.blade.create.nameError': 'Zone name cannot be empty',
      'newnity.edit.zone.blade.saveAtLocationLevel': 'Save at location level',
      'newnity.edit.zone.blade.create.locationError': 'Please select a location',
      'newnity.edit.zone.blade.locationLabel': 'Location',
      'newnity.edit.zone.toast.create.success': 'Zone created successfully',
      'newnity.edit.zone.toast.success': 'Zone saved successfully',
      'newnity.edit.zone.blade.playlists': 'Playlists',
      'newnity.edit.zone.blade.playlists.create': 'Add',

      'newnity.zone.list.blade.title': 'Music Zones',
      'newnity.zone.delete.confirm.title': 'Delete zone',
      'newnity.zone.delete.currently.editing': 'You are currently editing this zone. ',
      'newnity.zone.delete.confirm.message':
        'This operation is irreversible! Are you sure you want to delete zone "{{zoneName}}?"',
      'newnity.zone.delete.confirm.generic': 'the selected zone',
      'newnity.delete.zone.toast.success': 'Zone "{{name}}" successfully deleted',
      'newnity.delete.zone.toast.error': 'Failed to delete zone "{{name}}"',

      'newnity.edit.visuals.blade.create.title': 'Create Visual Channel',
      'newnity.edit.visuals.blade.create.header': 'Visual',
      'newnity.edit.visuals.blade.create.name': 'Name',
      'newnity.edit.visuals.blade.create.nameError': 'Visual name cannot be empty',
      'newnity.edit.visuals.blade.saveAtLocationLevel': 'Save at location level',
      'newnity.edit.visuals.blade.create.locationError': 'Please select a location',
      'newnity.edit.visuals.blade.locationLabel': 'Location',
      'newnity.edit.visuals.blade.typeLabel': 'Type',
      'newnity.edit.visuals.toast.create.success': 'Visual channel created successfully',
      'newnity.edit.visuals.toast.success': 'Visual channel saved successfully',

      'newnity.visuals.list.blade.title': 'Visual Channels',
      'newnity.visuals.delete.confirm.title': 'Delete zone',
      'newnity.visuals.delete.currently.editing': 'You are currently editing this visual channel. ',
      'newnity.visuals.delete.confirm.message':
        'This operation is irreversible! Are you sure you want to delete channel "{{visualName}}?"',
      'newnity.visuals.delete.confirm.generic': 'the selected visual channel',
      'newnity.delete.visuals.toast.success': 'Visual channel "{{name}}" successfully deleted',
      'newnity.delete.visuals.toast.error': 'Failed to delete visual channel "{{name}}"',

      'newnity.onHold.list.blade.title': 'On-Hold Zones',
      'newnity.onHold.delete.confirm.title': 'Delete On-Hold Zone',
      'newnity.onHold.delete.currently.editing': 'You are currently editing this on-hold channel. ',
      'newnity.onHold.delete.confirm.message':
        'This operation is irreversible! Are you sure you want to delete channel "{{onHoldName}}?"',
      'newnity.onHold.delete.confirm.generic': 'the selected on-hold channel',
      'newnity.delete.onHold.toast.success': 'On-hold channel "{{name}}" successfully deleted',
      'newnity.delete.onHold.toast.error': 'Failed to delete on-hold channel "{{name}}"',
      'newnity.edit.onHold.toast.create.success': 'On-hold channel created successfully',
      'newnity.edit.onHold.toast.success': 'On-hold channel saved successfully',
      'newnity.edit.onHold.blade.create.title': 'Create On-Hold Zone',
      'newnity.edit.onHold.blade.create.header': 'On-Hold',
      'newnity.edit.onHold.blade.create.name': 'Name',
      'newnity.edit.onHold.blade.create.nameError': 'On-hold channel name cannot be empty',

      'newnity.create.location.tab.details': 'Location details',
      'newnity.create.location.tab.defaultSettings': 'Default settings',
      'newnity.create.location.tab.details.oracleNumber': 'Oracle location number',
      'newnity.create.location.tab.details.name': 'Location name',
      'newnity.create.location.tab.details.siteNumber': 'Site number',
      'newnity.create.location.tab.details.timezone': 'Timezone',
      'newnity.create.location.tab.details.country': 'Country',
      'newnity.create.location.tab.details.city': 'City',
      'newnity.create.location.tab.details.state': 'State/Province',
      'newnity.create.location.tab.details.zipCode': 'Zip/Postal code',
      'newnity.create.location.tab.details.address': 'Address',
      'newnity.create.location.tab.details.devices': 'Devices...',
      'newnity.create.location.tab.details.users': 'Users...',
      'nCompanySearchResult.columnTitle.name': 'Company name',
      'nCompanySearchResult.columnTitle.number': 'Company number',
      'nCompanySearchResult.columnTitle.city': 'City',
      'nCompanySearchResult.columnTitle.country': 'Country',
      'nLocationSearchResult.columnTitle.locationNumber': 'Oracle location number',
      'nLocationSearchResult.columnTitle.locationName': 'Location name',
      'nLocationSearchResult.columnTitle.siteNumber': 'Site number',
      'nLocationSearchResult.columnTitle.companyName': 'Company',
      'nDeviceSearchResult.columnTitle.id': 'Device number',
      'nDeviceSearchResult.columnTitle.serial': 'Serial number',
      'nDeviceSearchResult.columnTitle.salesOrder': 'Sales order number',
      'nDeviceSearchResult.columnTitle.deviceDescription': 'Device description',
      'nDeviceSearchResult.columnTitle.locationName': 'Location name',
      'nDeviceSearchResult.columnTitle.locationNumber': 'Location number',
      'nDeviceSearchResult.columnTitle.siteNumber': 'Site number',
      'nDeviceSearchResult.columnTitle.companyName': 'Company',
      'deviceMode.singleZone': 'Single Zone Audio',
      'deviceMode.dualZone': 'Dual Zone Audio',
      'nProgram.columnTitle.id': 'Program id',
      'nProgram.columnTitle.name': 'Program Name',
      'nProgram.columnTitle.genre': 'Genre',
      'nProgram.columnTitle.description': 'Description',
      'nProgram.columnTitle.albumArt': 'Album Art',
      'nProgram.columnTitle.artists': 'Artists',
      'programs.name': 'Program Name',
      'programs.genre': 'Genre',
      'newnity.search.noresults': 'No results found',
      'newnity.search.locationAddress': 'Location Address',
      'newnity.edit.location.toast.success': 'Location data saved',
      'newnity.edit.location.toast.create.success': 'Location created successfully',
      'newnity.edit.location.blade.create.title': 'Create location',
      'newnity.edit.location.tab.details': 'Location details',
      'newnity.edit.location.tab.details.oracleNumber': 'Oracle location number',
      'newnity.edit.location.tab.details.name': 'Location name',
      'newnity.edit.location.tab.details.clientSiteId': 'Site number',
      'newnity.edit.location.tab.details.timezone': 'Timezone',
      'newnity.edit.location.tab.details.country': 'Country',
      'newnity.edit.location.tab.details.city': 'City',
      'newnity.edit.location.tab.details.state': 'State/Province',
      'newnity.edit.location.tab.details.zipCode': 'Zip/Postal code',
      'newnity.edit.location.tab.details.address': 'Address',
      'newnity.edit.location.tab.details.devices': 'Devices...',
      'newnity.edit.location.users.title': 'Location Users',
      'newnity.list.location.delete.confirm.title': 'Delete location',
      'newnity.list.location.delete.confirm.content':
        'Are you sure you want to delete {{locationName}}?',
      'newnity.list.location.delete.currently.editing': 'You are currently editing this item. ',
      'newnity.list.location.delete.confirm.warning': ' This operation cannot be reverted!',
      'newnity.list.location.delete.table.location': 'Location to be deleted ',
      'newnity.list.location.delete.table.device': 'Devices to be deleted ',
      'newnity.list.location.delete.table.workgroup': 'Workgroups to be deleted ',
      'newnity.list.station.delete.confirm.title': 'Delete playlist',
      'newnity.list.station.delete.confirm.content':
        'Are you sure you want to delete {{stationName}}?',
      'newnity.list.station.delete.currently.editing': 'You are currently editing this item. ',
      'newnity.list.station.blade.title': 'Playlists',
      'newnity.delete.station.toast.success': 'Station "{{name}}" successfully deleted',
      'newnity.delete.station.toast.error': 'Failed to delete station "{{name}}"',
      'newnity.edit.station.blade.isDefault': 'Playlist is set as default',
      'newnity.edit.station.blade.setAsDefault': 'Set this playlist as default',
      'newnity.edit.station.blade.blockRemoteScrolling': 'Block schedule override',
      'newnity.edit.station.blade.header.addProgram': 'Add Program',
      'newnity.edit.station.blade.header.changeProgram': 'Change program',
      'newnity.edit.station.blade.header.addSilence': 'Add Silence',
      'newnity.edit.station.blade.slots.silence': 'Silence',
      'newnity.edit.station.blade.slots.empty': 'Empty slot',
      'newnity.edit.station.blade.scheduleSlots': 'Schedule slots',
      'newnity.edit.station.blade.defaultSlot': 'Default slot',
      'newnity.edit.station.blade.header.removeProgram': 'Remove Program',
      'newnity.edit.station.blade.create.title': 'Create playlist',
      'newnity.edit.station.blade.create.nameError': 'Playlist name cannot be empty',
      'newnity.edit.station.blade.create.name': 'Playlist name',
      'newnity.edit.station.blade.create.header': 'Playlist',
      'newnity.edit.station.toast.success': 'Playlist data saved',
      'newnity.edit.station.toast.create.success': 'Playlist created successfully',
      'newnity.edit.station.slot.columnTitle.name': 'Name',
      'newnity.edit.station.slot.columnTitle.genre': 'Genre',
      'newnity.edit.station.slot.schedule.create.tooltip': 'Create slot schedule',
      'nStation.columnTitle.id': 'Playlist Id',
      'nStation.columnTitle.name': 'Playlist name',
      'nStation.columnTitle.isDefault': 'Default playlist',
      'nSearchCompany.columnTitle.name': 'Company name',
      'nSearchCompany.columnTitle.number': 'Company number',
      'nSearchCompany.columnTitle.city': 'City',
      'nSearchCompany.columnTitle.country': 'Country',
      'nSearchLocation.columnTitle.locationNumber': 'Oracle location number',
      'nSearchLocation.columnTitle.locationName': 'Location name',
      'nSearchLocation.columnTitle.siteNumber': 'Site number',
      'nSearchLocation.columnTitle.companyName': 'Company',
      'nSearchDevice.columnTitle.deviceNumber': 'Device number',
      'nSearchDevice.columnTitle.serial': 'Serial number',
      'nSearchDevice.columnTitle.salesOrder': 'Sales order number',
      'nSearchDevice.columnTitle.deviceDescription': 'Device description',
      'nSearchDevice.columnTitle.locationName': 'Location name',
      'nSearchDevice.columnTitle.locationNumber': 'Location number',
      'nSearchDevice.columnTitle.siteNumber': 'Site number',
      'nSearchDevice.columnTitle.companyName': 'Company',
      'deviceList.serialNumber': 'Serial number',
      'deviceList.name': 'Device name',
      'deviceList.deviceSettings.select.location': 'Please select a location first',
      'deviceList.id': 'Device number',
      'deviceList.deviceTypeName': 'Player type',
      'deviceList.salesOrderNumber': 'Sales order number',
      'deviceList.description': 'Description',
      'deviceList.locationName': 'Location name',
      'deviceList.locationNumber': 'Location number',
      'devices.tooltip.add': 'Create new device',
      'devices.tooltip.edit': 'Edit device',
      'devices.tooltip.delete': 'Delete device',
      'zones.name': 'Name',
      'zones.workgroupName': 'Level',
      'visuals.name': 'Name',
      'visuals.workgroupName': 'Level',
      'onHoldList.name': 'Name',
      'onHoldList.workgroupName': 'Level',
      'zoneList.tooltip.add': 'Create new zone',
      'zoneList.tooltip.edit': 'Edit zone',
      'zoneList.tooltip.delete': 'Delete zone',
      'locations.name': 'Name',
      'locations.country': 'Country',
      'locations.city': 'City',
      'locations.timezone': 'Timezone',
      'locations.oracleNumber': 'Oracle location Number',
      'locations.clientSiteId': 'Site number',
      'locations.tooltip.add': 'Create new location',
      'locations.tooltip.edit': 'Edit location',
      'locations.tooltip.delete': 'Delete location',
      'onHoldList.tooltip.add': 'Create new on-hold channel',
      'onHoldList.tooltip.edit': 'Edit on-hold channel',
      'onHoldList.tooltip.delete': 'Delete on-hold channel',
      'newnity.search.tab.company.address': 'Company Address',
      'newnity.device.properties.tab': 'Properties',
      'newnity.device.edit.number': 'Device number',
      'newnity.device.edit.zone': 'Music Zone',
      'newnity.device.edit.visual.audio.output': 'Audio Output For Video',
      'newnity.device.edit.visual': 'Visual Channel',
      'newnity.device.edit.name': 'Device name',
      'newnity.device.edit.serialNumber': 'Serial number',
      'newnity.device.edit.serialNumber.locked.tooltip': 'Unlock serial number editing',
      'newnity.device.edit.serialNumber.unlocked.tooltip': 'Lock serial number editing',
      'newnity.device.edit.salesOrderNumber': 'Sales order',
      'newnity.device.edit.description': 'Description',
      'newnity.device.edit.deviceTypeId': 'Device type',
      'newnity.device.edit.deviceMode': 'Device Mode',
      'newnity.device.edit.location': 'Assign to location (Oracle location number | Location name)',
      'newnity.device.edit.station': 'Select Music Playlist',
      'newnity.device.edit.toast.create.success': 'Device created successfully',
      'newnity.device.edit.toast.success': 'Device data saved',
      'newnity.device.edit.leftZone': 'Zone 1',
      'newnity.device.edit.rightZone': 'Zone 2',
      'newnity.device.edit.leftZonePlaylist': 'Zone 1 Playlist',
      'newnity.device.edit.rightZonePlaylist': 'Zone 2 Playlist',
      'newnity.device.edit.audioOutputForVideo.all': 'All Zones',
      'newnity.device.edit.audioOutputForVideo.left': 'Zone 1 (Left Zone)',
      'newnity.device.edit.audioOutputForVideo.right': 'Zone 2 (Right Zone)',
      'newnity.device.edit.audioOverlap':
        'Music and Video audio outputs will overlap. Are you sure you want to continue?',
      'newnity.device.edit.videoAudioDisabled':
        'Audio output for the video channel will be disabled. Are you sure you want to continue?',
      'newnity.device.delete.confirm.title': 'Delete device',
      'newnity.device.delete.confirm.message':
        'This operation is irreversible! Are you sure you want to delete {{deviceSerial}}?',
      'newnity.device.delete.confirm.serial': 'the selected device',
      'newnity.device.delete.currently.editing': 'You are currently editing this item. ',
      'newnity.details': 'Details',
      'newnity.android': 'Android',
      'newnity.device.edit.title': 'Create device',
      'newnity.device.edit.channelType.video': 'Looping Channel',
      'newnity.device.edit.channelType.vod': 'Video On Demand',
      'newnity.device.edit.channelType.onHold': 'On-Hold',
      'newnity.device.edit.channelType.music': 'Music',
      'newnity.device.edit.channelType.musicMessage': 'Music Message',
      'newnity.device.required.location': 'Location is required',
      'newnity.device.required.name': 'Device name is required',
      'newnity.time': 'Time',
      'newnity.days': 'Days',
      'newnity.schedule.startTime': 'Start time',
      'newnity.schedule.endTime': 'End time',
      'newnity.schedule.startDate': 'Start date',
      'newnity.schedule.endDate': 'End date',
      'newnity.schedule.title': 'Program Schedule',
      'newnity.schedule.noDaysError': 'Please select at least one day',
      'newnity.monday': 'Monday',
      'newnity.tuesday': 'Tuesday',
      'newnity.wednesday': 'Wednesday',
      'newnity.thursday': 'Thursday',
      'newnity.friday': 'Friday',
      'newnity.saturday': 'Saturday',
      'newnity.sunday': 'Sunday',
      'newnity.schedule.startDateEndDate': 'Start date greater than end date',
      'new.user': 'New User',
      'newnity.device.serial.characters':
        'Spaces and characters \\, /, :, *, ?, ", <, >, | are not supported',
      'newnity.add.users.title': 'Add Users',
      'user.details.title': 'User Details',
      'user.details.lockUser': 'Lock User',
      'user.details.unlockUser': 'Unlock User',
      'user.edit.addToWorkgroup': 'Add user to',
      'deviceSettings.showCritical': 'Show critical settings',
      'deviceSettings.criticalSetting': 'This setting is CRITICAL',
      'deviceSettings.value': 'Value',
      'deviceSettings.isInherited': 'Inherited',
      'deviceSettings.riskLevel': 'Risk Level',
      'deviceSettings.riskLevelLabel': 'Risk Level',
      'deviceSettings.timestamp': 'Timestamp',
      'deviceSettings.origin': 'Origin',
      'deviceSettings.username': 'User',
      'deviceSettings.setting': 'Setting',
      'deviceSettings.title': 'Device Settings',
      'deviceSettings.valueDialogTitle': 'Value for {{key}}',
      'deviceSettings.dangerZone': 'DANGER ZONE',
      'deviceSettings.dangerZoneWarning': 'You are about to modify a critical setting!',
      'deviceSettings.dangerZoneReadMe':
        'A wrong value may have irreversible impact on the devices!',
      'deviceSettings.dangerZoneMath': 'Please answer the following math question to proceed:',
      'deviceSettings.dangerZoneCheckbox': 'Yes, I understand the risk',
      'deviceSettings.applyingSettings': 'Applying Settings',
      'deviceSettings.youCanStillCancel': 'You can still cancel these changes',
      'deviceSettings.cancelChanges': 'Cancel Changes',
      'deviceSettings.playerCountReadme':
        'By continuing, you are going to affect {{count}} devices.',
      'deviceSettings.playerCountDialogInfo':
        'Please type in the number of devices to confirm you want to proceed:',
      'deviceSettings.playerCountLabel': 'Number of devices',
      'deviceSettings.saved': 'Done',
      'deviceSettings.saveSuccessToast': 'Changes pushed successfully',
      'deviceSettings.deleteOverride': 'Delete overridden value and inherit from parent',
      'deviceSettings.returnToOverride': 'Use overridden value',
      'deviceSettings.removeSetting': 'Remove this setting from editting',
      'deviceSettings.summarySettingsForSavingPart1':
        'The following settings have changes and will be saved. Remove any setting by clicking the ',
      'deviceSettings.summarySettingsForSavingPart2': ' button.',
      'deviceSettings.summarySettingsNotForSavingPart1':
        'These settings have not been changed and will not be saved. Add any setting as override by clicking the ',
      'deviceSettings.summarySettingsNotForSavingPart2': ' button.',
      'deviceSettings.summaryRemoveSetting': 'Remove setting',
      'deviceSettings.summaryAddSetting': 'Add setting',

      // Device Settings Keys
      securitySettings: 'Security Settings',
      'securitySettings.vodAppPassword': 'VOD App password',
      'securitySettings.playerConfigAppPassword': 'Player Config App Password',
      'securitySettings.diagnosticsAppPassword': 'Diagnostics App Password',
      'securitySettings.encryptMediaFiles': 'Encrypt Media Files',

      siteSettings: 'Site Settings',
      'siteSettings.siteId': 'Site Id',
      'siteSettings.bandwidthSettings': 'Bandwidth Settings',
      'siteSettings.sleepMode': 'Sleep Mode',
      'siteSettings.openingHours': 'Opening Hour',
      'siteSettings.customParamsMap1': 'Custom Params Map 01',
      'siteSettings.customParamsMap2': 'Custom Params Map 02',
      'siteSettings.customParamsMap3': 'Custom Params Map 03',
      'siteSettings.customParamsMap4': 'Custom Params Map 04',
      'siteSettings.customParamsMap5': 'Custom Params Map 05',
      'siteSettings.customParamsMap6': 'Custom Params Map 06',
      'siteSettings.customParamsMap7': 'Custom Params Map 07',
      'siteSettings.customParamsMap8': 'Custom Params Map 08',
      'siteSettings.customParamsMap9': 'Custom Params Map 09',
      'siteSettings.customParamsMap10': 'Custom Params Map 10',
      'siteSettings.customParamsMap11': 'Custom Params Map 11',
      'siteSettings.customParamsMap12': 'Custom Params Map 12',
      'siteSettings.customParamsMap13': 'Custom Params Map 13',
      'siteSettings.customParamsMap14': 'Custom Params Map 14',
      'siteSettings.customParamsMap15': 'Custom Params Map 15',
      'siteSettings.contentDownloadWindows': 'Content Download Window',
      'siteSettings.zipCode': 'Zip Code',
      'siteSettings.longitude': 'Longitude',
      'siteSettings.latitude': 'Latitude',

      cloudApiSettings: 'Cloud API Settings',
      'cloudApiSettings.baseServerUpgradeUrl': 'Base Server Upgrade URL',
      'cloudApiSettings.baseServerApiUrl': 'Base Server API URL',

      contentSettings: 'Content Settings',
      'contentSettings.storedTracksCountLimit': 'Stored Tracks Count Limit',
      'contentSettings.networkNotAvailableMessage': 'Network Not Available Message',

      platformSettings: 'Platform Settings',
      'platformSettings.zidooSoundOutputRaw': 'Zidoo Sound Output Raw',
      'platformSettings.usePowerKeyForScreenOff': 'Use Power Key For Screen Off',
      'platformSettings.adbEnabled': 'ADB Enabled',

      serialDisplayCommands: 'Serial Display Commands',
      'serialDisplayCommands.commandsMap': 'Commands Map',

      soundSettings: 'Sound Settings',
      'soundSettings.volumeLevels': 'Volume Levels',

      hardwareSettings: 'Hardware Settings',
      'hardwareSettings.serial': 'Serial',
      'hardwareSettings.deviceModel': 'Device Model',
      'hardwareSettings.networkHostname': 'Network Hostname',
      'hardwareSettings.knownSerialProductIds': 'Known Serial Product Ids',

      softwareSettings: 'Software Settings',
      'softwareSettings.headlessSetupEnabled': 'Headless Setup Enabled',
      'softwareSettings.liveCommandsEnabled': 'Live Commands Enabled',
      'softwareSettings.earlyAdopter': 'Early Adopter',
      'softwareSettings.httpServiceMaxWorkers': 'HTTP Service Max Workers',

      resetSettings: 'Reset Settings',
      'softwareSettings.resetSettings.resetMode': 'Reset Mode',
      'softwareSettings.resetSettings.resetTime': 'Reset Time',
      'softwareSettings.resetSettings.exceptions': 'Reset Exceptions',

      debugSettings: 'Debug Settings',
      'softwareSettings.debugSettings.streamMusicTracks': 'Stream Music Tracks',
      'softwareSettings.debugSettings.networkSetupDisabled': 'Disable Network Setup',
      'softwareSettings.debugSettings.playerFilesUpdateDisabled': 'Disable Player Files Update',
      'softwareSettings.debugSettings.deleteMediaOnLaunch': 'Delete Media on Launch',
      'softwareSettings.debugSettings.deletePlaylistOnLaunch': 'Delete Playlist On Launch',
      'softwareSettings.debugSettings.playbackLogsUploadDisabled': 'Disable Playback Logs Upload',
      'softwareSettings.debugSettings.monitoringDisabled': 'Disable Monitoring',
      'softwareSettings.debugSettings.useHeadlessSetupWebAppDevBuild':
        'Use Headless Setup WebApp Dev Build',
      'softwareSettings.debugSettings.crashLoggerEnabled': 'Enable Crash Logger',
      'softwareSettings.debugSettings.debugViewVisible': 'Debug View Visible',

      watchdogSettings: 'Watchdog Settings',
      'watchdogSettings.playbackRestartTimeoutMillis': 'Playback Restart Timeout (ms)',
      'watchdogSettings.watchdogEnabled': 'Watchdog Enabled',

      displaySettings: 'Display Settings',
      'displaySettings.displayScale': 'Scale',
      'displaySettings.displayResolution': 'Resolution',
      'displaySettings.displayOrientation': 'Orientation',
      'displaySettings.displayBrightness': 'Brightness',
      'displaySettings.displayDensity': 'Density',

      purgeSettings: 'Purge Settings',
      'contentSettings.purgeSettings.mediaFilesPurgeUntouchedDays':
        'Purge media files untouched # days',
      'contentSettings.purgeSettings.logsPurgeUntouchedDays': 'Purge logs untouched # days',
      'contentSettings.purgeSettings.statisticsPurgeUntouchedDays':
        'Purge statistics untouched # days',
      'contentSettings.purgeSettings.customDirectories': 'Custom Directories',

      loggingSettings: 'Logging Settings',
      'loggingSettings.activityLogsAutoUploadEnabled': 'Enable Activity Logs Auto Upload',
      'loggingSettings.activityLogsAutoUploadIntervalMillis':
        'Auto Upload Activity Logs Interval (ms)',
      'loggingSettings.playbackLogsFileEnabled': 'Enable Playback Logs File',
      'loggingSettings.activityLogsFileEnabled': 'Enable Activity Logs File',
      'loggingSettings.activityLogsBufferMaxSizeBytes': 'Activity Logs Buffer Max Size (bytes)',

      cronSettings: 'Cron Settings',
      'cronSettings.monitorDataUploadIntervalSeconds': 'Monitor Data Upload Interval (s)',
      'cronSettings.playlistFeedsUpdateIntervalSeconds': 'Playlist Feeds Update Interval (s)',
      'cronSettings.playlistUpdateIntervalSeconds': 'Playlist Update Interval (s)',
      'cronSettings.playerFilesDownloadIntervalSeconds': 'Player Files Download Interval (s)',

      clientSettings: 'Client Settings',
      'clientSettings.projectId': 'Project Id',

      timeSettings: 'Time Settings',
      'timeSettings.timeZone': 'Timezone',

      timeSyncSettings: 'Time Sync Settings',
      'timeSettings.timeSyncSettings.syncMode': 'Sync Mode',
      'timeSettings.timeSyncSettings.customNtpServerUrl': 'Custom NTP Server URL',

      playbackSettings: 'Playback Settings',
      'playbackSettings.multiZoneNoMediaFillColor': 'Multizone No Media Fill Color',
      'playbackSettings.vodSettings.mediaListZoneFrameLandscape': 'Media List Zone Frame Landscape',
      'playbackSettings.syncPlaybackTimeoutMillis': 'Playback Sync Timeout (ms)',
      'playbackSettings.playlistUpdateInstantReload': 'Instant Reload on Playlist Update',
      'playbackSettings.forceReportNowPlayingData': 'Force Report Now Playing Data',

      flashMediaSettings: 'Flash Media Settings',
      'playbackSettings.flashMediaSettings.useAirRuntime': 'Use AIR Runtime',

      vodSettings: 'VOD Settings',
      'playbackSettings.vodSettings.mediaFrameFocusBackgroundColor': 'Media Frame Focus BG Color',
      'playbackSettings.vodSettings.mediaTitleTextColor': 'Media Title Text Color',
      'playbackSettings.vodSettings.mediaTitleTextColorFocused': 'Media Title Focused Text Color',
      'playbackSettings.vodSettings.isHintEnable': 'Enable Hint',
      'playbackSettings.vodSettings.mediaFrameBackgroundColor': 'Media Frame BG Color',
      'playbackSettings.vodSettings.timeoutIntervalSeconds': 'Timeout Interval (s)',
      'playbackSettings.vodSettings.mediaListZoneFramePortrait': 'Media List Zone Frame Portrait',

      videoSettings: 'Video Settings',
      'playbackSettings.videoSettings.videoPlayerType': 'Player Type',
      'playbackSettings.videoSettings.videoRenderingMode': 'Rendering Mode',

      webPageSettings: 'Web Page Settings',
      'playbackSettings.webPageSettings.purgeWebCacheOnStart': 'Purge Cache on Start',
      'playbackSettings.webPageSettings.crosswalkUseTextureView': 'Use Crosswalk Texture View',
      'playbackSettings.webPageSettings.allowedUrls': 'Allowed URLs',

      audioSettings: 'Audio Settings',
      'playbackSettings.audioSettings.duckingDuration': 'Ducking Duration',
      'playbackSettings.audioSettings.duckingPercent': 'Ducking (%)',
      'playbackSettings.audioSettings.musicCrossfadeMode': 'Music Crossfade Mode',
      'playbackSettings.audioSettings.audioPlayerType': 'Player Type',
      'playbackSettings.audioSettings.musicTracksVolume': 'Music Tracks Volume',
      'playbackSettings.audioSettings.musicSmoothStopDurationMillis':
        'Music Smooth Stop Duration (ms)',
      'playbackSettings.audioSettings.musicStaticCrossfadeDurationMillis':
        'Music Static Crossfade Duration (ms)',
      'playbackSettings.audioSettings.musicSmoothStopPercent': 'Music Smooth Stop (%)',
      'playbackSettings.audioSettings.volumePercentFactorZoneLeft':
        'Volume Percent Factor Zone Left',
      'playbackSettings.audioSettings.volumePercentFactorZoneRight':
        'Volume Percent Factor Zone Right',
      'playbackSettings.audioSettings.musicTracksVolumeZoneLeft': 'Music Tracks Volume Left Zone',
      'playbackSettings.audioSettings.musicTracksVolumeZoneRight': 'Music Tracks Volume Right Zone',

      playbackInteractionSettings: 'Playback Interaction Settings',
      'playbackSettings.playbackInteractionSettings.onTouchAction': 'On Touch Action',

      appCommunicationSettings: 'App Communication Settings',

      multicastSettings: 'Multicast Settings',
      'appCommunicationSettings.multicastSettings.defaultMulticastPort': 'Default Port',
      'appCommunicationSettings.multicastSettings.defaultMulticastAddress': 'Default Address',

      ipcSettings: 'IPC Settings',
      'appCommunicationSettings.ipcSettings.tcpPort': 'TCP Port',
      'appCommunicationSettings.ipcSettings.datagramReceivePort': 'Datagram Receive Port',
      'appCommunicationSettings.ipcSettings.datagramSendPort': 'Datagram Send Port',

      p2pSettings: 'P2P Settings',
      'appCommunicationSettings.p2pSettings.port': 'Port',
      'appCommunicationSettings.p2pSettings.clientEnabled': 'Enable Client',
      'appCommunicationSettings.p2pSettings.serverEnabled': 'Enable Server',

      networkSettings: 'Network Settings',
      wifiSettings: 'Wifi Settings',
      'networkSettings.wifiSettings.allowConfigurationChange': 'Allow Configuration Change',
      networkLinkSettings: 'Network Link Settings',
      wifiAccessPointSettings: 'Wifi Access Point Settings',
      'networkSettings.wifiSettings.wifiAccessPointSettings.ssid': 'SSID',
      'networkSettings.wifiSettings.wifiAccessPointSettings.password': 'Password',
      'networkSettings.wifiSettings.wifiAccessPointSettings.authenticationType':
        'Authentication Type',
      'networkSettings.wifiSettings.dhcpEnabled': 'Enable DHCP',
      'networkSettings.wifiSettings.networkLinkSettings.dns1': 'DNS1',
      'networkSettings.wifiSettings.networkLinkSettings.dns2': 'DNS2',
      'networkSettings.wifiSettings.networkLinkSettings.gateway': 'Gateway',
      'networkSettings.wifiSettings.networkLinkSettings.ipAddress': 'IP Address',
      'networkSettings.wifiSettings.networkLinkSettings.netmask': 'Netmask',
      'networkSettings.wifiSettings.interfaceEnabled': 'Interface Enabled',
      'networkSettings.wifiSettings.vlanConfigurations': 'VLAN Configurations',

      httpProxySettings: 'HTTP Proxy Settings',
      'networkSettings.httpProxySettings.port': 'Port',
      'networkSettings.httpProxySettings.host': 'Host',
      'networkSettings.httpProxySettings.proxyEnabled': 'Enable Proxy',
      'networkSettings.httpProxySettings.excludedHostsList': 'Excluded Hosts',
      'networkSettings.httpProxySettings.password': 'Password',
      'networkSettings.httpProxySettings.username': 'Username',

      ethernetSettings: 'Ethernet Settings',
      'networkSettings.ethernetSettings.dhcpEnabled': 'Enable DHCP',
      'networkSettings.ethernetSettings.vlanConfigurations': 'VLAN Configurations',
      'networkSettings.ethernetSettings.allowConfigurationChange': 'Allow Configuration Change',
      'networkSettings.ethernetSettings.interfaceEnabled': 'Interface Enabled',
      'networkSettings.ethernetSettings.networkLinkSettings.dns1': 'DNS1',
      'networkSettings.ethernetSettings.networkLinkSettings.dns2': 'DNS2',
      'networkSettings.ethernetSettings.networkLinkSettings.gateway': 'Gateway',
      'networkSettings.ethernetSettings.networkLinkSettings.ipAddress': 'IP Address',
      'networkSettings.ethernetSettings.networkLinkSettings.netmask': 'Netmask',

      'settingsEditor.propertiesCountMultiple': '{{count}} properties',
      'settingsEditor.propertiesCountSingle': '1 property',
      'settingsEditor.itemsCountMultiple': '{{count}} items',
      'settingsEditor.itemsCountSingle': '1 item',
      'settingsEditor.propertiesCount': '{{count}} properties',
      'settingsEditor.propertyKey': 'Property key',
      'settingsEditor.dialog.editKey': 'Edit key',
      'settingsEditor.dialog.addKey': 'Add key',
      'settingsEditor.button.editKey': 'Edit key',
      'settingsEditor.button.addItem': 'Add item',
      'settingsEditor.button.cloneItem': 'Clone item',
      'settingsEditor.button.removeItem': 'Remove item',
      'settingsEditor.unlock': 'Unlock device setting',
      'settingsEditor.validation.required': 'Value cannot be empty',
      'settingsEditor.validation.greaterThan': 'Value must be greater than {{value}}',
      'settingsEditor.validation.lessThan': 'Value must be less than {{value}}',
      'settingsEditor.validation.greaterOrEqualThan':
        'Value must be greater than or equal to {{value}}',
      'settingsEditor.validation.lessOrEqualThan': 'Value must be less than or equal to {{value}}',
      'settingsEditor.validation.pattern': 'Incorrect value format',
      'settingsEditor.validation.longerThan': 'Value must be longer than {{value}} characters',
      'settingsEditor.validation.shorterThan': 'Value must be shorter than {{value}} characters',
      'settingsEditor.riskLevel0': 'Critical',
      'settingsEditor.riskLevel1': 'High',
      'settingsEditor.riskLevel2': 'Medium',
      'settingsEditor.riskLevel3': 'Low',
      'settingsEditor.riskLevel4': 'No risk',
      settingValueSearch: 'Contains',

      'wizard.title': 'Edit Settings',
      'wizard.settingsStepper.back': 'Back',
      'wizard.settingsStepper.next': 'Next',
      'wizard.settingsStepper.save': 'Save',
      'wizard.settingsStepper.settingsSelector': 'Select Settings',
      'wizard.settingsStepper.setValues': 'Set Values',
      'wizard.settingsStepper.setTarget': 'Set Target',
      'settingsSelector.setting': 'Setting',
      'settingsSelector.value': 'Value',
      'wizard.settingsStepper.cancel': 'Cancel',
      'wizard.dirtyDialog.unsavedChangesMessage1': 'Are you sure you want to go back?',
      'wizard.dirtyDialog.unsavedChangesMessage2': 'The changes you have made will be lost.',
      'wizard.dirtyDialog.title': 'Back to settings list',
      'wizard.dirtyDialog.andmore': '...and {{count}} more',
      'wizard.dirtyDialog.listHeader': 'You have changed',
      'deviceSettings.edit': 'Edit device settings',
      'warning!': 'Warning!',
    },
  },
  fr: {
    translation: {
      'Welcome to Admin App': 'Omlette du Admin App avec baguette',
      home: 'Home fr',
      workgroups: 'Workgroups fr',
      settings: 'Settings fr',
      users: 'Users fr',
      players: 'Players fr',
      'blade.close.confirmation': 'Are you sure you want to close {{bladeName}} fr?',
      'blade.close.dirty': 'The folowing blades have unsaved data: fr',
      'blade.close.all': 'Close all fr',
      cancel: 'Cancel fr',
      create: 'Create fr',
      delete: 'Delete fr',
      favorites: 'Favorites fr',
      search: 'Search fr',
      'search.placeholder': 'Type something for search fr...',
      'workgroups.expand.tooltip': 'Expand all workgroups fr',
      'workgroups.collapse.tooltip': 'Collapse all workgroups fr',
      'workgroups.select.tooltip': 'Select all workgroups fr',
      'workgroups.unselect.tooltip': 'Deselect all workgroups fr',
      'workgroups.export.tooltip': 'Export selected workgroups fr',
      name: 'Name',
      save: 'Save',
      'field.required': 'Field is required',
      'workgroups.templateLibraries.brand': 'Brand',
      'workgroups.templateLibraries.logoPath': 'Logo',
      'workgroups.brands.defaultLogo': 'Default Logo',
      'workgroups.brands.brandType': 'Brand Type',
      'workgroup.users': 'Users',
      'workgroup.users.remove': 'Remove Users',
      'workgroup.users.add': 'Add Users',
      'workgroup.users.edit': 'Edit User',
      'workgroup.users.remove.confirm.title': 'Remove User',
      'workgroup.users.remove.confirm.message':
        'Are you sure you want to remove user {{userLogin}}?',
      'workgroup.users.remove.confirm.generic': 'Are you sure you want to remove {{count}} users?',
      'workgroupUsers.id': 'ID',
      'workgroupUsers.login': 'Login',
      'workgroupUsers.email': 'Email',
      'workgroupUsers.firstName': 'First Name',
      'workgroupUsers.lastName': 'Last Name',
      'workgroupUsers.passwordExpire': 'Password Expire',
      'workgroupUsers.lockLevel': 'Is Locked',
      'workgroupUsers.lockReason': 'Lock Reason',
      'workgroupUsers.addExistingUser': 'Add Existing User',
      'workgroupUsers.tooltip.add': 'Add existing user',
      'workgroupUsers.tooltip.create': 'Create new user',
      'workgroupUsers.tooltip.edit': 'Edit user',
      'workgroupUsers.tooltip.delete': 'Remove user',

      'user.userAccessTitle': 'User Access',
      'user.userAccessSelectionRequired': 'Please select an access right type',
      'user.userHasExtendedAccess':
        '* User has extended application access. (One or more additional applications)',
      'user.messageOnlyAccessRightsTitle': 'Messaging only: ',
      'user.messageOnlyAccessRightsDesc': 'Messaging, Devices, Locations, Reports',
      'user.musicOnlyAccessRightsTitle': 'Music only: ',
      'user.musicOnlyAccessRightsDesc': 'Music, Messaging, Devices, Locations, Reports',
      'user.visualsOnlyAccessRightsTitle': 'Visuals only: ',
      'user.visualsOnlyAccessRightsDesc': 'Visuals, Devices, Locations, Reports',
      'user.visualsAndMusicAccessRightsTitle': 'Music and Visuals: ',
      'user.visualsAndMusicAccessRightsDesc':
        'Music, Messaging, Visuals, Devices, Locations, Reports',

      'user.edit.firstName': 'First Name',
      'user.edit.lastName': 'Last Name',
      'user.edit.login': 'Login ID',
      'user.edit.email': 'Email',
      'user.edit.password': 'Password',
      'user.edit.passwordVerify': 'Verify Password',
      'user.edit.changePassword': 'Change Password',
      'user.edit.changeLogin': 'Change Login',
      'user.edit.passwordError': 'Passwords must match',
      'user.edit.invalid.email': 'Email must be of format [text]@[domain].[extension]',
      'users.company.blade.title': 'Company Users',
      'user.edit.uncomplaint.password':
        'Password must be at least 12 characters long and contain at least one lower case letter, one upper case letter, one digit and one special character',
      'user.edit.welcomeEmailTemplate': 'Email Template',
      'user.edit.sendWelcomeEmailOnCreate': 'Send welcome email on create',
      'user.edit.sendWelcomeEmailOnSave': 'Send welcome email on save',
      'user.edit.lastWelcomeEmailDate': 'Last welcome email sent on',
      'user.edit.previewEmail': 'Preview',
      'user.edit.addToWorkgroup': 'Add user to',
      'user.details.title': 'User Details',
      'user.details.lockUser': 'Lock User',
      'user.details.unlockUser': 'Unlock User',
      'deviceSettings.edit': 'Edit device settings',
    },
  },
  de: {
    translation: {
      'Welcome to Admin App': 'Admin App über alles!!!',
      home: 'Home de',
      workgroups: 'Workgroups de',
      settings: 'Settings de',
      users: 'Users de',
      players: 'Players de',
      'blade.close.confirmation': 'Are you sure you want to close {{bladeName}} de?',
      'blade.close.dirty': 'The folowing blades have unsaved data: de',
      'blade.close.all': 'Close all de',
      cancel: 'Cancel de',
      create: 'Create de',
      delete: 'Delete fr',
      favorites: 'Favorites de',
      search: 'Search de',
      'search.placeholder': 'Type something for search de...',
      'workgroups.expand.tooltip': 'Expand all workgroups de',
      'workgroups.collapse.tooltip': 'Collapse all workgroups de',
      'workgroups.select.tooltip': 'Select all workgroups de',
      'workgroups.unselect.tooltip': 'Deselect all workgroups de',
      'workgroups.export.tooltip': 'Export selected workgroups de',
      name: 'Name',
      save: 'Save',
      'field.required': 'Field is required',
      'workgroups.templateLibraries.brand': 'Brand',
      'workgroups.brands.defaultLogo': 'Default Logo',
      'workgroups.brands.brandType': 'Brand Type',
      'workgroup.users': 'Users',
      'workgroup.users.remove': 'Remove Users',
      'workgroup.users.add': 'Add Users',
      'workgroup.users.edit': 'Edit User',
      'workgroup.users.remove.confirm.title': 'Remove User',
      'workgroup.users.remove.confirm.message':
        'Are you sure you want to remove user {{userLogin}}?',
      'workgroup.users.remove.confirm.generic': 'Are you sure you want to remove {{count}} users?',
      'workgroupUsers.id': 'ID',
      'workgroupUsers.login': 'Login',
      'workgroupUsers.email': 'Email',
      'workgroupUsers.firstName': 'First Name',
      'workgroupUsers.lastName': 'Last Name',
      'workgroupUsers.passwordExpire': 'Password Expire',
      'workgroupUsers.lockLevel': 'Is Locked',
      'workgroupUsers.lockReason': 'Lock Reason',
      'workgroupUsers.addExistingUser': 'Add Existing User',
      'workgroupUsers.tooltip.add': 'Add existing user',
      'workgroupUsers.tooltip.create': 'Create new user',
      'workgroupUsers.tooltip.edit': 'Edit user',
      'workgroupUsers.tooltip.delete': 'Remove user',

      'user.userAccessTitle': 'User Access',
      'user.userAccessSelectionRequired': 'Please select an access right type',
      'user.userHasExtendedAccess':
        '* User has extended application access. (One or more additional applications)',
      'user.messageOnlyAccessRightsTitle': 'Messaging only: ',
      'user.messageOnlyAccessRightsDesc': 'Messaging, Devices, Locations, Reports',
      'user.musicOnlyAccessRightsTitle': 'Music only: ',
      'user.musicOnlyAccessRightsDesc': 'Music, Messaging, Devices, Locations, Reports',
      'user.visualsOnlyAccessRightsTitle': 'Visuals only: ',
      'user.visualsOnlyAccessRightsDesc': 'Visuals, Devices, Locations, Reports',
      'user.visualsAndMusicAccessRightsTitle': 'Music and Visuals: ',
      'user.visualsAndMusicAccessRightsDesc':
        'Music, Messaging, Visuals, Devices, Locations, Reports',

      'user.edit.firstName': 'First Name',
      'user.edit.lastName': 'Last Name',
      'user.edit.login': 'Login ID',
      'user.edit.email': 'Email',
      'user.edit.password': 'Password',
      'user.edit.passwordVerify': 'Verify Password',
      'user.edit.changePassword': 'Change Password',
      'user.edit.changeLogin': 'Change Login',
      'user.edit.passwordError': 'Passwords must match',
      'user.edit.invalid.email': 'Email must be of format [text]@[domain].[extension]',
      'users.company.blade.title': 'Company Users',
      'user.edit.uncomplaint.password':
        'Password must be at least 12 characters long and contain at least one lower case letter, one upper case letter, one digit and one special character',
      'user.edit.welcomeEmailTemplate': 'Email Template',
      'user.edit.sendWelcomeEmailOnCreate': 'Send welcome email on create',
      'user.edit.sendWelcomeEmailOnSave': 'Send welcome email on save',
      'user.edit.lastWelcomeEmailDate': 'Last welcome email sent on',
      'user.edit.previewEmail': 'Preview',
      'user.edit.addToWorkgroup': 'Add user to',
      'user.details.title': 'User Details',
      'user.details.lockUser': 'Lock User',
      'user.details.unlockUser': 'Unlock User',
      'deviceSettings.edit': 'Edit device settings',
    },
  },
};
