import { selectCompanyById } from 'app/dataStore/selectors/newnityCompanies';
import { NewnityEntityType, NLocation } from 'app/dataStore/types';
import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions,
  BladeProps,
  deviceSettingsActions,
  EntityType,
  useDefaultDeviceSettingFns,
  withRegisterBlade,
} from 'react-tools';

import i18n from '../../../../../localization/i18n';
import { DataStoreSelectors, NDevice, PlayerType } from '../../../../dataStore';
import { deviceFinishEdit, deviceSetLocation, setDeviceSaved } from '../../../duck/actions';
import {
  selectCompanyId,
  selectLeftZonePlaylists,
  selectRightZonePlaylists,
  selectZonePlaylists,
} from '../../../duck/selectors';
import {
  fetchChannels,
  fetchDevice,
  fetchLocations,
  fetchPlaylistsForZone,
  fetchStream,
  saveDevice,
} from '../../../duck/thunks';
import { DeviceMode, NewnityState, StreamAudioOutput } from '../../../duck/types';
import { Device } from './device';

export interface DeviceContainerProps extends BladeProps {
  deviceId?: number;
  openedFromLocation?: number;
}

export const getEmptyDevice = (): NDevice => ({
  id: 0,
  name: '',
  deviceTypeName: 'Android',
  deviceTypeId: PlayerType.Android,
  serialNumber: '',
  salesOrderNumber: '',
  description: '',
  locationId: 0,
  locationName: '',
  locationNumber: '',
  stationId: 0,
  stationName: '',
  leftZone: 0,
  rightZone: 0,
  channelId: 0,
  leftZonePlaylist: 0,
  rightZonePlaylist: 0,
  visualChannelId: 0,
  audioOutputForVisuals: StreamAudioOutput.LeftZone,
  deviceMode: DeviceMode.SingleZone,
});

const getDevice = (state: any, id: number | undefined) => {
  if (!id) {
    return getEmptyDevice();
  }

  let device = {
    ...DataStoreSelectors.getDataStoreItems(state, NewnityEntityType.NDevice)[
    id
    ] as NDevice
  }

  if (device === undefined) {
    device = getEmptyDevice();
    device.id = id;
  }

  if ('stationId' in device === false) {
    device = {
      ...device,
      stationId: getEmptyDevice().stationId,
    };
  }

  return {
    ...device,
    channelId: device.channelId ? device.channelId : 0,
    stationId: device.stationId ? device.stationId : 0,
    leftZone: device.leftZone ? device.leftZone : 0,
    leftZonePlaylist: device.leftZonePlaylist ? device.leftZonePlaylist : 0,
    rightZone: device.rightZone ? device.rightZone : 0,
    rightZonePlaylist: device.rightZonePlaylist ? device.rightZonePlaylist : 0,
    visualChannelId: device.visualChannelId ? device.visualChannelId : 0,
    deviceMode: device.leftZone || device.rightZone ? DeviceMode.DualZone : DeviceMode.SingleZone,
    audioOutputForVisuals: device.audioOutputForVisuals === undefined ? -1 : device.audioOutputForVisuals
  };
};

const Container = ({
  deviceId,
  openedFromLocation,
  ...bladeProps
}: DeviceContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const companyId = useSelector(selectCompanyId);
  const workgroup = useSelector((state) => selectCompanyById(state, companyId));
  const device = useSelector((state) => getDevice(state, deviceId));
  const locations = useSelector(DataStoreSelectors.NLocation.selectLocationItems);
  const isSaving = useSelector(
    (state: any) => (state.newnity as NewnityState).currentDevice.isSaving
  );
  const saved = useSelector((state: any) => (state.newnity as NewnityState).currentDevice.saved);
  let leftZonePlaylists = useSelector(selectLeftZonePlaylists);
  let rightZonePlaylists = useSelector(selectRightZonePlaylists);
  let zonePlaylists = useSelector(selectZonePlaylists);
  const isLoading = useSelector(
    (state) =>
      DataStoreSelectors.getDataStoreItemsFetching(state, NewnityEntityType.NStation).isFetching ||
      DataStoreSelectors.getDataStoreItemsFetching(state, NewnityEntityType.NLocation).isFetching ||
      DataStoreSelectors.getDataStoreItemsFetching(state, NewnityEntityType.NDevice).isFetching
  );
  const visuals = useSelector(DataStoreSelectors.ChannelSelectors.selectVisualItems);

  const zones = useSelector(DataStoreSelectors.ChannelSelectors.selectAudioChannelItems);
  if (!device.locationId && openedFromLocation) {
    device.locationId = openedFromLocation;
  }

  const saveDeviceAction: any = (device: NDevice) => dispatch<any>(saveDevice(device));
  const setDeviceSavedAction: any = (value: boolean) => dispatch<any>(setDeviceSaved(value));
  const fetchDeviceAction: any = (deviceId: number) => dispatch<any>(fetchDevice(deviceId));
  const fetchLocationsAction: any = (companyId: number) => dispatch<any>(fetchLocations(companyId));
  const fetchStations = () => {
    /** TODO: rework this */
  };
  const deviceSetLocationAction: any = (location: NLocation) =>
    dispatch<any>(deviceSetLocation(location.id));
  const forceCloseBlade = () => {
    dispatch(BladeActions.forceCloseBlade(bladeProps.bladeId));
    dispatch<any>(deviceFinishEdit());
  };
  const fetchZonePlaylists = (
    zoneId: number,
    companyId: number,
    leftOrRight: 'left' | 'right' | undefined
  ) => dispatch<any>(fetchPlaylistsForZone(zoneId, companyId, leftOrRight));
  const onCloseBlade = () => {
    dispatch<any>(deviceFinishEdit());
    dispatch(deviceSettingsActions.clearDeviceSettings());
  };

  const { fetchDevicesSettings, openDeviceSettings } = useDefaultDeviceSettingFns(
    bladeProps.bladeId,
    {
      entityId: device.locationId,
      entityType: EntityType.Site,
    }
  );

  const openDeviceSettingsBlade = useCallback(
    () => dispatch(BladeActions.closeChildrenBlades(bladeProps.bladeId, openDeviceSettings)),
    [openDeviceSettings, bladeProps.bladeId]
  );

  const fetchChannelsAction: any = (companyId: number) => dispatch<any>(fetchChannels(companyId));
  const fetchStreamAction: any = (streamId: number) => dispatch<any>(fetchStream(streamId));

  const clearDeviceSettings = () => {
    dispatch(deviceSettingsActions.clearDeviceSettings());
  };

  return (
    <Device
      companyId={companyId}
      device={device}
      locations={locations}
      isSaving={isSaving}
      isLoading={isLoading}
      saved={saved}
      zones={zones}
      workgroup={workgroup}
      openedFromLocation={openedFromLocation}
      leftZonePlaylists={leftZonePlaylists}
      rightZonePlaylists={rightZonePlaylists}
      zonePlaylists={zonePlaylists}
      saveDevice={saveDeviceAction}
      setDeviceSaved={setDeviceSavedAction}
      fetchDevice={fetchDeviceAction}
      fetchLocations={fetchLocationsAction}
      fetchStations={fetchStations}
      deviceSetLocation={deviceSetLocationAction}
      forceCloseBlade={forceCloseBlade}
      fetchZonePlaylists={fetchZonePlaylists}
      onCloseBlade={onCloseBlade}
      fetchDevicesSettings={fetchDevicesSettings}
      openDeviceSettings={openDeviceSettingsBlade}
      visuals={visuals}
      fetchChannels={fetchChannelsAction}
      fetchStream={fetchStreamAction}
      clearDeviceSettings={clearDeviceSettings}
      {...bladeProps}
    />
  );
};

export const DeviceBladeType = 'DEVICE';

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 600 },
  bladeType: DeviceBladeType,
  id: (_: any) => DeviceBladeType,
  title: (ownProps: DeviceContainerProps) =>
    ownProps.deviceId ? `Device ${ownProps.deviceId}` : i18n.t('newnity.device.edit.title'),
  allowMultipleInstances: false,
};

export const DeviceContainer = withRegisterBlade<DeviceContainerProps>(bladeConfig)(Container);
