import { GroupBox } from 'app/components';
import { UserDto, UserForm } from 'app/users/duck/types';
import { addMinutes, format } from 'date-fns';
import { debounce } from 'debounce';
import { FastField, Field, FieldProps, Formik, FormikErrors, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FadeIn from 'react-fade-in';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  RadioGroup,
  Radio,
  Box,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import {
  getFormEmailError,
  getFormEmailTemplateError,
  getFormPasswordError,
  helperText,
  isEmailValid,
  isPasswordCompliant,
  validateEmptyField,
} from '../../../../utils/formik';
import { useStyles } from './editUser.jss';

import { BladeProps, Footer, FooterSubmit, useBladeClosing } from 'react-tools';

export interface EditUserProps {
  workgroupId: number;
  user: UserForm;
  isFetching: boolean;
  isSaving: boolean;
  welcomeEmailTemplates: string[];
  workgroupName: string;
  automaticallyAdd: boolean;
}

export interface EditUserActions {
  fetchUser: (userId: number, workgroupId: number) => void;
  saveUser: (user: UserDto, workgroupId?: number, addToWorkgroup?: boolean) => void;
  fetchEmailTemplates: () => void;
  closeBlade: () => void;
}

type Props = EditUserProps & EditUserActions & BladeProps;

const validateFields = ['firstName', 'lastName', 'email', 'login', 'password', 'passwordVerify'];

export const SwitchLabel = (props: { companyName: string }) => {
  const [t] = useTranslation();
  return (
    <span>
      {t<string>('user.edit.addToWorkgroup')} <b>{props.companyName}</b>
    </span>
  );
};

export const EditUser: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [changePassword, setChangePassword] = useState(false);
  const [changeLogin, setChangeLogin] = useState(false);
  const [addToWorkgroupOnSave, setAddToWorkgroupOnSave] = useState(props.automaticallyAdd);
  const { fetchUser, setDirty } = props;

  useEffect(() => {
    props.fetchEmailTemplates();
  }, []);

  useBladeClosing(
    props.bladeId,
    () => !props.isDirty && !props.isSaving,
    () => props.closeBlade()
  );

  useEffect(() => {
    if (props.user.id) {
      fetchUser(props.user.id, props.workgroupId);
    }
  }, [props.user.id, props.workgroupId]);

  const checkAndSetFormValidationErrors = (values: UserForm) => {
    const errors: FormikErrors<UserForm> = {};
    const fieldRequiredErrorText = t('field.required');

    Object.keys(values).map((fieldName: string) => {
      const fieldExists = validateFields.includes(fieldName);
      const fieldHasNoValue = !values[fieldName as keyof UserForm];

      if (fieldExists && fieldHasNoValue) {
        errors[fieldName as keyof UserForm] = fieldRequiredErrorText;
      }
    });

    return errors;
  };

  const validateForm = useCallback(
    (values: UserForm) => {
      const errors: FormikErrors<UserForm> = checkAndSetFormValidationErrors(values);

      if (values.accessRights === '0' && props.user.id === 0) {
        errors.accessRights = 'invalid';

        return errors;
      }

      const formHasMissingValues = errors.password || errors.passwordVerify || errors.email;
      if (formHasMissingValues) {
        return errors;
      }

      if (
        (values.changePassword && values.id > 0 && !isPasswordCompliant(values.password)) ||
        values.password !== values.passwordVerify ||
        (props.user.id === 0 && !isPasswordCompliant(values.password))
      ) {
        errors.password = 'invalid';
        errors.passwordVerify = 'invalid';
      }

      if (!isEmailValid(values.email)) {
        errors.email = 'invalid';
      }

      const hasEmailTemplateError = Boolean(
        getFormEmailTemplateError(values.welcomeEmailTemplateName, values.sendWelcomeEmail)
      );

      if (hasEmailTemplateError) {
        errors.welcomeEmailTemplateName = 'invalid';
      }

      return errors;
    },
    [t, props.user.id]
  );

  const activatePreviewButton = useCallback(
    (values: UserForm) => {
      const formHasErrors = Object.getOwnPropertyNames(validateForm(values)).length > 0;
      const formHasNoEmailTemplates = values.welcomeEmailTemplateName.length === 0;

      return formHasErrors || formHasNoEmailTemplates;
    },
    [validateForm]
  );

  const setWelcomeEmailTemplateNameFieldValue = useCallback(
    (form: FormikProps<UserForm>, event: React.ChangeEvent<any>) => {
      const template = props.welcomeEmailTemplates.find(
        (currentTemplate: any) => currentTemplate === event.target.value
      );

      if (!template) {
        return;
      }
      form.setFieldValue('welcomeEmailTemplateName', template);
    },
    [props.welcomeEmailTemplates]
  );

  const openPreview = (values: UserForm) => {
    if (activatePreviewButton(values)) {
      return;
    }
    window.open(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/users/preview/${values.welcomeEmailTemplateName}`,
      '_blank'
    );
  };

  const togleUserLocked = useCallback(
    (form: FormikProps<UserForm>, lockLevel?: number) => {
      return () => {
        const newLockLevel = lockLevel === 0 ? 2 : 0;
        form.setFieldValue('lockLevel', newLockLevel);
        setDirty(true);
      };
    },
    [setDirty]
  );

  const toggleAddToWorkgroup = useCallback(
    () => setAddToWorkgroupOnSave(!addToWorkgroupOnSave),
    [addToWorkgroupOnSave]
  );

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
          validate={validateForm}
          initialValues={props.user}
          onSubmit={(values: UserForm) => {
            props.saveUser(
              { ...values, changePassword, changeLogin },
              undefined,
              addToWorkgroupOnSave
            );
          }}
          render={({ submitForm }) => {
            return (
              <form
                onClick={(e) => e.preventDefault()}
                className={classes.form}
                autoComplete={'off'}
                onChange={props.isDirty ? undefined : debounce(() => props.setDirty(true), 250)}
              >
                <div className={classes.formContent}>
                  <GroupBox
                    title={t<string>('user.details.title')}
                    headerContent={
                      <>
                        <FastField
                          name="lockLevel"
                          render={({ field, form }: FieldProps<UserForm>) => {
                            return (
                              <div className={classes.lockButtonContainer}>
                                {form.values.lockLevel && form.values.lockLevel > 0 ? (
                                  <Typography variant="subtitle1">
                                    {t<string>('user.details.unlockUser')}
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle1">
                                    {t<string>('user.details.lockUser')}
                                  </Typography>
                                )}
                                <Tooltip
                                  title={
                                    form.values.lockLevel &&
                                    form.values.lockLevel > 0 &&
                                    props.user.lockReason
                                      ? props.user.lockReason
                                      : ''
                                  }
                                >
                                  <IconButton
                                    onClick={togleUserLocked(form, form.values.lockLevel)}
                                  >
                                    {form.values.lockLevel && form.values.lockLevel > 0 ? (
                                      <Lock color="secondary" />
                                    ) : (
                                      <LockOpen />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <input
                                  {...field}
                                  type="hidden"
                                  onChange={(_) => {}}
                                  value={form.values.lockLevel || 0}
                                />
                              </div>
                            );
                          }}
                        ></FastField>
                      </>
                    }
                  >
                    <FastField
                      name="firstName"
                      render={({ field, form }: FieldProps<UserForm>) => {
                        return (
                          <TextField
                            autoFocus={true}
                            autoComplete={'new-password'}
                            label={t<string>('user.edit.firstName')}
                            helperText={helperText(form, field)}
                            error={validateEmptyField(form, field)}
                            {...field}
                            value={form.values.firstName || ''}
                            className={classes.formField}
                            inputProps={{ maxLength: 255 }}
                          />
                        );
                      }}
                    />
                    <FastField
                      name="lastName"
                      render={({ field, form }: FieldProps<UserForm>) => {
                        return (
                          <TextField
                            label={t<string>('user.edit.lastName')}
                            autoComplete={'new-password'}
                            helperText={helperText(form, field)}
                            {...field}
                            error={validateEmptyField(form, field)}
                            value={form.values.lastName || ''}
                            className={classes.formField}
                            inputProps={{ maxLength: 255 }}
                          />
                        );
                      }}
                    />
                    <FastField
                      name="email"
                      render={({ field, form }: FieldProps<UserForm>) => {
                        const errEmail = getFormEmailError(form, field);
                        return (
                          <TextField
                            label={t<string>('user.edit.email')}
                            autoComplete={'new-password'}
                            {...field}
                            error={Boolean(errEmail)}
                            value={form.values.email || ''}
                            className={classes.formField}
                            helperText={errEmail}
                            inputProps={{ maxLength: 255 }}
                          />
                        );
                      }}
                    />
                    <Field
                      name="login"
                      render={({ field, form }: FieldProps<UserForm>) => (
                        <TextField
                          label={t<string>('user.edit.login')}
                          autoComplete={'new-password'}
                          {...field}
                          error={validateEmptyField(form, field)}
                          disabled={props.user.id > 0 && !changeLogin}
                          onKeyPress={(e) => {
                            if (e.charCode === 32) {
                              e.preventDefault();
                            }
                          }}
                          value={
                            changeLogin || props.user.id < 1
                              ? field.value
                              : form.initialValues.login
                          }
                          className={classes.formField}
                          helperText={helperText(form, field)}
                          inputProps={{ maxLength: 255 }}
                        />
                      )}
                    />
                    {props.user.id > 0 && (
                      <Field
                        name="changeLogin"
                        render={({ form }: FieldProps<UserForm>) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={() => {
                                  form.values.login = form.initialValues.login;
                                  setChangeLogin(!changeLogin);
                                }}
                                checked={changeLogin}
                                onChange={(_) => {}}
                                inputProps={{
                                  name: 'changeLogin',
                                  id: 'changeLogin',
                                }}
                              />
                            }
                            label={t<string>('user.edit.changeLogin')}
                          />
                        )}
                      />
                    )}
                    <Field
                      name="password"
                      render={({ field, form }: FieldProps<UserForm>) => {
                        const errorStr =
                          (changePassword && props.user.id > 0) || props.user.id === 0
                            ? getFormPasswordError(form, field, form.values.passwordVerify)
                            : '';

                        return (
                          <TextField
                            label={t<string>('user.edit.password')}
                            {...field}
                            autoComplete={'new-password'}
                            type={'password'}
                            disabled={props.user.id > 0 && !changePassword}
                            error={Boolean(errorStr)}
                            value={field.value || ''}
                            className={classes.formField}
                            helperText={errorStr}
                            inputProps={{ maxLength: 64 }}
                          />
                        );
                      }}
                    />
                    <Field
                      name="passwordVerify"
                      render={({ field, form }: FieldProps<UserForm>) => {
                        const error =
                          validateEmptyField(form, field) || field.value !== form.values.password;
                        const errorStr = t('user.edit.passwordError');

                        return (
                          <TextField
                            label={t<string>('user.edit.passwordVerify')}
                            {...field}
                            autoComplete={'new-password'}
                            type={'password'}
                            disabled={props.user.id > 0 && !changePassword}
                            error={error}
                            value={field.value || ''}
                            className={classes.formField}
                            helperText={error ? errorStr : ''}
                            inputProps={{ maxLength: 64 }}
                          />
                        );
                      }}
                    />
                    {props.user.id > 0 && (
                      <Field
                        name="changePassword"
                        render={() => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={() => setChangePassword(!changePassword)}
                                checked={changePassword}
                                onChange={(_) => {}}
                                inputProps={{
                                  name: 'changePassword',
                                  id: 'changePassword',
                                }}
                              />
                            }
                            label={t<string>('user.edit.changePassword')}
                          />
                        )}
                      />
                    )}

                    {props.user.id === 0 && (
                      <FormControlLabel
                        style={{ wordBreak: 'break-word' }}
                        disabled={props.automaticallyAdd}
                        control={
                          <Checkbox checked={addToWorkgroupOnSave} onClick={toggleAddToWorkgroup} />
                        }
                        label={<SwitchLabel companyName={props.workgroupName} />}
                      />
                    )}
                  </GroupBox>

                  <GroupBox title={t<string>('user.userAccessTitle')}>
                    <Field name="accessRights">
                      {({ field, form }: FieldProps) => {
                        const hasError = field.value === '0' && props.user.id === 0;
                        const helperText = t<string>('user.userAccessSelectionRequired');
                        return (
                          <FormControl fullWidth style={{ width: '100%' }}>
                            <InputLabel error={true}></InputLabel>
                            <RadioGroup aria-label="profileApps" {...field}>
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={
                                  <Typography component="div">
                                    <span style={{ fontWeight: 'bold' }}>
                                      {t<string>('user.musicOnlyAccessRightsTitle')}
                                    </span>
                                    <span>{t<string>('user.musicOnlyAccessRightsDesc')} </span>
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label={
                                  <Typography component="div">
                                    <span style={{ fontWeight: 'bold' }}>
                                      {t<string>('user.visualsOnlyAccessRightsTitle')}
                                    </span>
                                    <span>{t<string>('user.visualsOnlyAccessRightsDesc')} </span>
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="5"
                                control={<Radio />}
                                label={
                                  <Typography component="div">
                                    <span style={{ fontWeight: 'bold' }}>
                                      {t<string>('user.messageOnlyAccessRightsTitle')}
                                    </span>
                                    <span>{t<string>('user.messageOnlyAccessRightsDesc')} </span>
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="4"
                                control={<Radio />}
                                label={
                                  <Typography component="div">
                                    <span style={{ fontWeight: 'bold' }}>
                                      {t<string>('user.visualsAndMusicAccessRightsTitle')}
                                    </span>
                                    <span>
                                      {t<string>('user.visualsAndMusicAccessRightsDesc')}{' '}
                                    </span>
                                  </Typography>
                                }
                              />
                            </RadioGroup>

                            <FormHelperText error={hasError}>
                              {hasError ? helperText : ''}{' '}
                            </FormHelperText>
                          </FormControl>
                        );
                      }}
                    </Field>
                    {props.user.id > 0 &&
                    props.user.extendedApps &&
                    props.user.extendedApps >= 1 ? (
                      <Typography component="span">
                        {t<string>('user.userHasExtendedAccess')}{' '}
                      </Typography>
                    ) : (
                      <Typography />
                    )}
                  </GroupBox>
                  <GroupBox title={'Welcome Email'}>
                    <Field
                      name="welcomeEmailTemplateName"
                      render={({ field, form }: FieldProps<UserForm>) => (
                        <FormControl>
                          <InputLabel
                            error={Boolean(
                              getFormEmailTemplateError(
                                field.value.welcomeEmailTemplateName
                                  ? field.value.welcomeEmailTemplateName
                                  : '',
                                form.values.sendWelcomeEmail
                              )
                            )}
                          >
                            {t<string>('user.edit.welcomeEmailTemplate')}
                          </InputLabel>
                          <Select
                            value={field.value}
                            onChange={(e) => setWelcomeEmailTemplateNameFieldValue(form, e)}
                          >
                            {props.welcomeEmailTemplates.map((emailTemplateName) => (
                              <MenuItem key={emailTemplateName} value={emailTemplateName}>
                                {emailTemplateName
                                  .replace('WelcomeEmail', '')
                                  .replace('.htm', '')
                                  .trim()}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            error={Boolean(
                              getFormEmailTemplateError(
                                field.value.welcomeEmailTemplateName
                                  ? field.value.welcomeEmailTemplateName
                                  : '',
                                form.values.sendWelcomeEmail
                              )
                            )}
                          >
                            {getFormEmailTemplateError(
                              field.value.welcomeEmailTemplateName
                                ? field.value.welcomeEmailTemplateName
                                : '',
                              form.values.sendWelcomeEmail
                            )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                    <Field
                      name="sendEmail"
                      render={({ form }: FieldProps<UserForm>) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={() => {
                                form.setFieldValue(
                                  'sendWelcomeEmail',
                                  !form.values.sendWelcomeEmail
                                );
                              }}
                              checked={form.values.sendWelcomeEmail}
                              onChange={(_) => {}}
                              inputProps={{
                                name: 'sendEmail',
                                id: 'sendEmail',
                              }}
                            />
                          }
                          label={
                            props.user.id > 0
                              ? t('user.edit.sendWelcomeEmailOnSave')
                              : t('user.edit.sendWelcomeEmailOnCreate')
                          }
                        />
                      )}
                    />
                    <Field
                      name="preview"
                      render={({ form }: FieldProps<UserForm>) => (
                        <Button
                          onClick={() => {
                            openPreview(form.values);
                          }}
                          disabled={activatePreviewButton(form.values)}
                          className={classes.previewButton}
                        >
                          {' '}
                          {t<string>('user.edit.previewEmail')}
                        </Button>
                      )}
                    />

                    {props.user.id > 0 && (
                      <FastField
                        name="lastWelcomeEmailDate"
                        render={({ field }: FieldProps<UserForm>) => {
                          const oldDate = new Date(String(field.value));
                          const offset = new Date().getTimezoneOffset();
                          const result = field.value
                            ? format(addMinutes(oldDate, -1 * offset), 'MM/dd/yyyy h:mm a')
                            : '';
                          return (
                            <TextField
                              label={t<string>('user.edit.lastWelcomeEmailDate')}
                              disabled={true}
                              value={result}
                            />
                          );
                        }}
                      />
                    )}
                  </GroupBox>
                </div>
                <Footer>
                  <FooterSubmit
                    hasCancelButton={true}
                    cancelButtonLabel={t<string>('footer.cancel')}
                    cancel={props.closeBlade}
                    submit={submitForm}
                    submitButtonLabel={t<string>(props.user.id ? 'footer.save' : 'footer.create')}
                    submitInProgress={props.isSaving || props.isFetching}
                  />
                </Footer>
              </form>
            );
          }}
        />
      </FadeIn>
    </div>
  );
};
